import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { EngagementCampaignStatus } from '@/graphql';

export const EngagementCampaignsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryIds: SearchSchema.types.arrayString(),
      salesPicIds: SearchSchema.types.arrayString(),
      hubspotDealIds: SearchSchema.types.arrayString(),
      influencerPicIds: SearchSchema.types.arrayString(),
      campaignCategoryIds: SearchSchema.types.arrayString(),
      status: SearchSchema.types.enum(EngagementCampaignStatus)
    })
  }
});

export type EngagementCampaignsSearchSchemaType = z.infer<typeof EngagementCampaignsSearchSchema>;
export type EngagementCampaignsFilterFormValues = EngagementCampaignsSearchSchemaType['filter'];
export type EngagementCampaignsFilterFormKeys = UnionOfKeys<EngagementCampaignsFilterFormValues>;
