import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { usePaymentsFilter } from './hooks';
import { Template, TemplateProps } from './Template';

export type PaymentsFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const PaymentsFilter = (props: PaymentsFilterProps) => {
  const { filter, setFilterForm } = usePaymentsFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template {...props} />} />
    </FilterWrapper>
  );
};

export { usePaymentsFilter };
export * from './schemaTypes';
