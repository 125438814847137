import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { LIMIT_50 } from '@/shared/constants';
import { getYesterday } from '@/shared/utils';
import { SearchSchema } from '@/shared/validations';

export enum TagType {
  HASHTAG = 'HASHTAG',
  KEYWORD = 'KEYWORD'
}
export interface TagAnalyticsType {
  id: number;
  label: string;
}

export const TagsAnalyticsSearchSchema = SearchSchema.create({
  limit: LIMIT_50,
  filter: {
    schema: z.object({
      date: SearchSchema.types.string(),
      tagId: SearchSchema.types.number(),
      keyword: SearchSchema.types.string()
    }),
    defaultValues: { date: getYesterday() }
  }
});

export type TagsAnalyticsSearchSchemaType = z.infer<typeof TagsAnalyticsSearchSchema>;
export type TagsAnalyticsFilterFormValues = TagsAnalyticsSearchSchemaType['filter'];
export type TagsAnalyticsFilterFormKeys = UnionOfKeys<TagsAnalyticsFilterFormValues>;
