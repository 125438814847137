import { useTranslation } from 'react-i18next';
import { useTranslateOptions } from '@/shared/hooks';
import { INFLUENCER_STATUS_OPTIONS } from '@/shared/constants';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const InfluencerStatusField = ({ multiple, ...props }: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const statusesOptions = useTranslateOptions(INFLUENCER_STATUS_OPTIONS);

  return (
    <SelectorField<InfluencersFilterFormKeys>
      multiple={multiple}
      title={t('Selector.Influencer Status')}
      options={statusesOptions}
      name={multiple ? 'statuses' : 'status'}
      {...props}
    />
  );
};
