import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, RenderDataWithFallback } from '@/shared/atoms';
import {
  THEME,
  MIN_AGE,
  MAX_AGE,
  MIN_FOLLOWERS,
  MAX_FOLLOWERS,
  DEFAULT_SIMILAR_INFLUENCERS_LIMITATION
} from '@/shared/constants';
import { useToggleState } from '@/shared/hooks';
import { SimilarInfluencerPopperFilter, SimilarInfluencerPopperFilterFormValues } from '@/shared/molecules';
import { RefetchSimilarInfluencersVariables } from '../schemaTypes';

export interface SimilarInfluencersTemplateProps {
  loading?: boolean;
  fetchLimit?: number;
  errorMessage?: string;
  displayLimit?: number;
  totalRecords?: number;
  onFetchData?: () => void;
  children: (props: { currentLimit: number }) => JSX.Element | JSX.Element[];
  onRefetchData?: (variables: RefetchSimilarInfluencersVariables) => Promise<void>;
  isSectionOpened: boolean;
}

export const SimilarInfluencersTemplate = ({
  loading,
  children,
  onFetchData,
  errorMessage,
  onRefetchData,
  isSectionOpened,
  totalRecords = 0,
  fetchLimit = DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.LIST.FETCH,
  displayLimit = DEFAULT_SIMILAR_INFLUENCERS_LIMITATION.LIST.DISPLAY
}: SimilarInfluencersTemplateProps) => {
  const { t } = useTranslation();
  const [currentLimit, setCurrentLimit] = useState(displayLimit);
  const { status: isExpanding, toggleStatus: toggleExpanding } = useToggleState(false);
  const [filterValues, setFilterValues] = useState<SimilarInfluencerPopperFilterFormValues>({
    genders: [],
    minAge: MIN_AGE,
    maxAge: MAX_AGE,
    minFollowers: MIN_FOLLOWERS,
    maxFollowers: MAX_FOLLOWERS
  });

  const handleMoreClick = () => {
    setCurrentLimit(isExpanding ? displayLimit : fetchLimit);
    toggleExpanding();
  };

  const handleFilterFormSubmit = async (formValues: SimilarInfluencerPopperFilterFormValues) => {
    setFilterValues(formValues);
    await onRefetchData?.({
      limit: fetchLimit,
      genders: formValues.genders,
      minAge: +formValues.minAge <= MIN_AGE ? null : +formValues.minAge,
      maxAge: +formValues.maxAge >= MAX_AGE ? null : +formValues.maxAge,
      minFollowers: +formValues.minFollowers <= MIN_FOLLOWERS ? null : +formValues.minFollowers,
      maxFollowers: +formValues.maxFollowers >= MAX_FOLLOWERS ? null : +formValues.maxFollowers
    });
  };

  useEffect(() => {
    if (isSectionOpened) {
      onFetchData?.();
    }
  }, [onFetchData, isSectionOpened]);

  return isSectionOpened ? (
    <div css={styles.wrapper(totalRecords)}>
      <div css={styles.heading}>
        <h3>{t('Similar Influencers')}</h3>
        <SimilarInfluencerPopperFilter defaultValues={filterValues} onSubmit={handleFilterFormSubmit} />
      </div>

      <RenderDataWithFallback
        loading={loading}
        hasNoData={!totalRecords}
        loadingProps={{ size: 32, css: { height: '72px', backgroundColor: THEME.background.colors.gray.lv1 } }}
        noDataNode={
          <div css={{ textAlign: 'center', height: '72px', lineHeight: '72px' }}>
            {errorMessage || t('Can not find any similar accounts')}
          </div>
        }
        skipDelayed
      >
        {children({ currentLimit })}

        {totalRecords > displayLimit ? (
          <Button
            loading={loading}
            onClick={handleMoreClick}
            css={{ padding: '0 16px', margin: '8px auto 0' }}
            title={t(isExpanding ? 'Button.Less' : 'Button.More')}
          />
        ) : null}
      </RenderDataWithFallback>
    </div>
  ) : null;
};

const styles = {
  wrapper: (totalRecords?: number) =>
    css({
      backgroundColor: THEME.background.colors.gray.lv1,
      borderBottom: totalRecords ? 'none' : THEME.border.base
    }),
  heading: css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
    borderBottom: THEME.border.base
  })
};
