import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { CampaignType, CampaignSocialMediaType, InfluencerCampaignListSortField } from '@/graphql';

export const InfluencerProfileCampaignsSearchSchema = SearchSchema.create({
  sort: { field: InfluencerCampaignListSortField },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      campaignType: SearchSchema.types.enum(CampaignType),
      socialMedias: SearchSchema.types.arrayEnum(CampaignSocialMediaType)
    })
  }
});

export type InfluencerProfileCampaignsSearchSchemaType = z.infer<typeof InfluencerProfileCampaignsSearchSchema>;
export type InfluencerProfileCampaignsFilterFormValues = InfluencerProfileCampaignsSearchSchemaType['filter'];
export type InfluencerProfileCampaignsFilterFormKeys = UnionOfKeys<InfluencerProfileCampaignsFilterFormValues>;
