import React, { createContext, useContext } from 'react';
import { InfluencerSearchSortField } from '@/graphql';
import { ListWithPagination } from '@/shared/molecules';
import { useInfluencerParamsSearch } from '../hooks';
import { InfluencersContextReturnType } from '../schemaTypes';
import { useAllInfluencersList } from './hooks';
import {
  InfluencerProfile,
  AllInfluencersProps,
  AllInfluencersSearchSchemaType,
  AllInfluencersFilterFormValues
} from './schemaTypes';

export type AllInfluencersProviderContextType = InfluencersContextReturnType<
  InfluencerProfile,
  AllInfluencersFilterFormValues,
  InfluencerSearchSortField
>;

const Context = createContext<AllInfluencersProviderContextType>({} as AllInfluencersProviderContextType);

interface Props {
  value: AllInfluencersProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const AllInfluencersProvider = ({
  children,
  value: { isPackagePage, isTtcmCampaign, isEngagementPage, getSortSelectButtonProps, getListSelectButtonProps }
}: Props) => {
  const paramsSearch = useInfluencerParamsSearch<AllInfluencersSearchSchemaType>();
  const { page, sort, limit, filter } = paramsSearch;
  const listProps = useAllInfluencersList({ page, sort, limit, filter });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        isPackagePage,
        isTtcmCampaign,
        isEngagementPage,
        getListSelectButtonProps,
        sortSelectButtonProps: getSortSelectButtonProps?.(listProps.listRecords, listProps.totalRecords)
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useAllInfluencersContext = () => useContext<AllInfluencersProviderContextType>(Context);
