import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { AutoManagedCampaignStatus, AutoManagedCampaignType, GetAutoManagedCampaignsSortField } from '@/graphql';

export const AutoManagedCampaignsSearchSchema = SearchSchema.create({
  sort: { field: GetAutoManagedCampaignsSortField },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      minPrice: SearchSchema.types.number(),
      maxPrice: SearchSchema.types.number(),
      categoryIds: SearchSchema.types.arrayString(),
      minCommissionRate: SearchSchema.types.number(),
      maxCommissionRate: SearchSchema.types.number(),
      status: SearchSchema.types.enum(AutoManagedCampaignStatus),
      marketplaceType: SearchSchema.types.enum(AutoManagedCampaignType)
    }),
    defaultValues: { status: AutoManagedCampaignStatus.ACTIVE }
  }
});

export type AutoManagedCampaignsSearchSchemaType = z.infer<typeof AutoManagedCampaignsSearchSchema>;
export type AutoManagedCampaignsFilterFormValues = AutoManagedCampaignsSearchSchemaType['filter'];
export type AutoManagedCampaignsFilterKeys = UnionOfKeys<AutoManagedCampaignsFilterFormValues>;
