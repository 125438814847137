import { SocialAccountType, TTCMStatus, TiktokInfluencer, TiktokInfluencerV2 } from '@/graphql';
import { TiktokInfluencerProfile } from './schemaTypes';

export const formatInfluencerProfile = (infl: TiktokInfluencer | TiktokInfluencerV2): TiktokInfluencerProfile => ({
  ...infl,
  id: Number(infl.id),
  socialAccountId: Number(infl.socialAccountId),
  socialAccountType: SocialAccountType.TIKTOK,
  isTTCMCreator: !!infl.ttcmStatus && infl.ttcmStatus === TTCMStatus.APPROVED,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id)
    })) || []
});
