import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { defaultProfilePostsFilterSchema } from '@/shared/organisms';
import { FacebookPageAnalyticsPostsSortOrder } from '@/graphql';

export const AnalyticsFbFeedPostSearchSchema = SearchSchema.create({
  filter: defaultProfilePostsFilterSchema,
  sort: { field: FacebookPageAnalyticsPostsSortOrder }
});

export type AnalyticsFbFeedPostSearchSchemaType = z.infer<typeof AnalyticsFbFeedPostSearchSchema>;
