import { useTranslation } from 'react-i18next';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useYoutubeAnalyticsTagsTopRankingQuery, useYoutubeAnalyticsTagsTopRankingCountQuery } from '@/graphql';
import {
  ProfilePostsCount,
  ProfilePostsFilter,
  AnalyticsYoutubeTagsRankingList,
  useYoutubeTagRankingSearchFilter
} from '@/shared/organisms';
import { useInitialAnalyticsPosts } from '../hooks';

export const AnalyticsYoutubeTagsRanking = () => {
  const { t } = useTranslation();
  const { brandAccountId, mainSocialAccountId } = useInitialAnalyticsPosts({
    socialMedia: 'youtube',
    document: (name) => ({
      title: name ? 'appHeader.TagRankingWithAccountName' : 'Tag Ranking',
      appHeader: name ? 'appHeader.TaggedPostsListWithAccountName' : 'Tag Ranking'
    })
  });
  const { page, limit, sort, filter, setFilter } = useYoutubeTagRankingSearchFilter();

  const skip = !brandAccountId || !mainSocialAccountId;
  const variables = { ...filter, youtubeAnalyticsAccountId: Number(mainSocialAccountId) };

  const { data, previousData, loading } = useYoutubeAnalyticsTagsTopRankingQuery({
    skip,
    variables: getValuableVariables({ limit, orderBy: sort, offset: getOffset(page, limit), ...variables })
  });
  // TODO: should combine with the query above same other pages
  const {
    data: countData,
    previousData: previousCountData,
    loading: fetchingCount
  } = useYoutubeAnalyticsTagsTopRankingCountQuery({
    skip,
    variables: getValuableVariables(variables)
  });

  const result = loading ? previousData : data;
  const countResult = fetchingCount ? previousCountData : countData;
  const totalRecords = countResult?.youtubeAnalyticsTagsTopRankingCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} countLabel={t('Tag found')} />

      <AnalyticsYoutubeTagsRankingList
        loading={loading || fetchingCount}
        data={result?.youtubeAnalyticsTagsTopRanking?.tags || []}
      />
    </ListWithPagination>
  );
};
