import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { MarketplacePostsFilterSchema } from '@/shared/organisms';

export const MarketplaceEditPostSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({ draftPost: SearchSchema.types.string() }).merge(MarketplacePostsFilterSchema)
  }
});

export type MarketplaceEditPostSearchSchemaType = z.infer<typeof MarketplaceEditPostSearchSchema>;
