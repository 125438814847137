import { ThreadsInfluencer, SocialAccountType } from '@/graphql';
import { ThreadsInfluencerProfile } from './schemaTypes';

export const formatInfluencerProfile = (infl: ThreadsInfluencer): ThreadsInfluencerProfile => ({
  id: infl.id,
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  avatar: infl.avatar,
  country: infl.country,
  averageLikes: infl.averageLikes,
  isBrandAccount: infl.isBrandAccount,
  socialAccountId: infl.socialAccountId,
  socialAccountType: SocialAccountType.THREADS,
  socialAccountStatus: infl.socialAccountStatus,
  socialAccountFollowers: infl.socialAccountFollowers,
  socialAccountEngagementFollowersRate: infl.socialAccountEngagementFollowersRate,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail, type, caption }) => ({
      type,
      thumbNail,
      id: postId,
      influencerId: Number(infl.id),
      caption
    })) || []
});
