import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { InstagramInfluencerV3, SocialAccountInfluencerListSortFieldV2, SocialAccountType } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const InstagramInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  minReach: true,
  maxReach: true,
  regionIds: true,
  interests: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  audienceAge: true,
  minSaveRate: true,
  maxSaveRate: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minStoryPrice: true,
  maxStoryPrice: true,
  audienceRatio: true,
  audienceGender: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  campaignCategoryIds: true,
  followerGrowthPeriod: true,
  followerGrowthPercentage: true,
  talentAgencyOrPartnerIds: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultInstagramInfluencersFormValues = InstagramInfluencersFilterSchema.parse({
  tab: InfluencersTabType.INSTAGRAM
});

export const InstagramInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: InstagramInfluencersFilterSchema,
    defaultValues: defaultInstagramInfluencersFormValues
  }
});

export type InstagramInfluencersSearchSchemaType = z.infer<typeof InstagramInfluencersSearchSchema>;
export type InstagramInfluencersFilterFormValues = InstagramInfluencersSearchSchemaType['filter'];
export type InstagramInfluencersFilterFormKeys = UnionOfKeys<InstagramInfluencersFilterFormValues>;
export type InstagramInfluencersProps = DefaultInfluencersProps<InstagramInfluencerProfile>;
export type InstagramInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  InstagramInfluencerV3,
  | 'id'
  | 'age'
  | 'name'
  | 'gender'
  | 'avatar'
  | 'country'
  | 'averageLikes'
  | 'averageReach'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'analyticsWarning'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementRate'
>;
