import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { AffiliateReportShowBy, MarketplaceAffiliateReportByInfluencerField } from '@/graphql';

export const AutoManagedReportSearchSchema = SearchSchema.create({
  sort: { field: MarketplaceAffiliateReportByInfluencerField },
  filter: {
    schema: z.object({
      endDate: SearchSchema.types.string().optional(),
      startDate: SearchSchema.types.string().optional(),
      mode: SearchSchema.types.enum(AffiliateReportShowBy)
    }),
    defaultValues: {
      endDate: undefined,
      startDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
      mode: AffiliateReportShowBy.DATE
    }
  }
});

export type AutoManagedReportSearchSchemaType = z.infer<typeof AutoManagedReportSearchSchema>;
export type AutoManagedReportFilterFormValues = AutoManagedReportSearchSchemaType['filter'];
