import { SocialAccountType, YouTubeInfluencer, YoutubeInfluencerV3 } from '@/graphql';
import { YoutubeInfluencerProfile } from './schemaTypes';

export const formatInfluencerProfile = (infl: YouTubeInfluencer | YoutubeInfluencerV3): YoutubeInfluencerProfile => ({
  id: String(infl.id),
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  avatar: infl.avatar,
  country: infl.country,
  averageLikes: infl.averageLikes,
  averageViews: infl.averageViews,
  isBrandAccount: infl.isBrandAccount,
  socialAccountType: SocialAccountType.YOUTUBE,
  socialAccountStatus: infl.socialAccountStatus,
  socialAccountId: String(infl.socialAccountId),
  socialAccountFollowers: infl.socialAccountFollowers,
  socialAccountEngagementViewsRate: infl.socialAccountEngagementViewsRate,
  socialAccountEngagementFollowersRate: infl.socialAccountEngagementFollowersRate,
  hasSimilarAccounts: (infl as YoutubeInfluencerV3).hasSimilarAccounts,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id)
    })) || []
});
