import { z } from 'zod';
import { getFirstDay } from '@/shared/utils';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { YoutubeCMSSegmentMode } from '../types';

export const YoutubeCMSFilterSchema = z.object({
  month: SearchSchema.types.string(),
  keyword: SearchSchema.types.string(),
  segment: SearchSchema.types.enum(YoutubeCMSSegmentMode)
});
export const YoutubeCMSSearchSchema = SearchSchema.create({
  filter: {
    schema: YoutubeCMSFilterSchema,
    defaultValues: { month: getFirstDay(), segment: YoutubeCMSSegmentMode.CHANNELS }
  }
});
export const YoutubeCMSChannelDetailsSearchSchema = SearchSchema.create({
  filter: {
    schema: YoutubeCMSFilterSchema,
    defaultValues: { month: getFirstDay(), segment: YoutubeCMSSegmentMode.ASSETS }
  }
});

export type YoutubeCMSSearchSchemaType = z.infer<typeof YoutubeCMSSearchSchema>;
export type YoutubeCMSFilterFormValues = YoutubeCMSSearchSchemaType['filter'];
export type YoutubeCMSFilterFormKeys = UnionOfKeys<YoutubeCMSFilterFormValues>;
