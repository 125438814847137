import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Option, SortOption, SortType } from '@/shared/types';
import { CheckBox, FormProvider, FormStyle } from '@/shared/atoms';
import { LIMIT, LIMIT_50, LIMIT_100, THEME } from '@/shared/constants';
import { BasicFilterWrapper, SelectorFieldV2 } from '@/shared/molecules';
import { InfluencersSortFormValues, SortSelectButtonProps } from '../schemaTypes';

interface TemplateProps<T extends string> {
  sortOptions?: SortOption<T>[];
  setLimit: (limit: number) => void;
  setSort: (sort: SortType<T>) => void;
  selectButtonProps?: SortSelectButtonProps;
}

const Template = <T extends string>({ setLimit, setSort, sortOptions, selectButtonProps }: TemplateProps<T>) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<{ [key: string]: string | string[] }>();

  const limitOptions: Option<string, string>[] = [
    { value: String(LIMIT), label: t<string>('View_limit', { count: LIMIT }) },
    { value: String(LIMIT_50), label: t<string>('View_limit', { count: LIMIT_50 }) },
    { value: String(LIMIT_100), label: t<string>('View_limit', { count: LIMIT_100 }) }
  ];

  const handleSortChange = (field: string, value: string, options: SortOption<T>[] = []) => {
    const selectedOption = options.find((o) => o.value === value);
    setValue(field, value);
    setSort({ field: (selectedOption?.field || null) as T, order: selectedOption?.order || null });
  };

  const handleLimitChange = (field: string, value: string) => {
    setValue(field, value);
    setLimit(Number(value));
  };

  return (
    <BasicFilterWrapper alignItems="center" css={styles.wrapper}>
      {selectButtonProps ? (
        <FormStyle.FieldWrapper css={{ flex: 'none' }}>
          <CheckBox
            css={{ fontSize: '14px', fontWeight: 600 }}
            {...selectButtonProps}
            tooltipProps={{
              help: t(selectButtonProps.hasAllSelected ? 'Unselect all' : 'Select all'),
              ...selectButtonProps.tooltipProps
            }}
          />
        </FormStyle.FieldWrapper>
      ) : null}

      {sortOptions ? (
        <SelectorFieldV2<keyof InfluencersSortFormValues<T>, SortOption<T>>
          name="sort"
          menuWidth="auto"
          options={sortOptions}
          optionLabelType="radio"
          onChange={handleSortChange}
          placeholder={t<string>('Selector.Sort')}
          css={{ marginLeft: 'auto', minWidth: '108px', maxWidth: 'max-content' }}
        />
      ) : null}

      <SelectorFieldV2<keyof InfluencersSortFormValues<T>>
        name="limit"
        options={limitOptions}
        css={{ maxWidth: '113px' }}
        onChange={handleLimitChange}
        clearable={false}
      />
    </BasicFilterWrapper>
  );
};

interface InfluencersSortTemplateProps<T extends string> extends TemplateProps<T> {
  initialValues: InfluencersSortFormValues<T>;
}

export const InfluencersSortTemplate = <T extends string>({
  sortOptions,
  initialValues,
  ...restProps
}: InfluencersSortTemplateProps<T>) => (
  <FormProvider
    values={{
      limit: initialValues.limit,
      sort:
        sortOptions?.find((o) => o.field === initialValues.sort.field && o.order === initialValues.sort.order)?.value ||
        ''
    }}
  >
    <Template<T> sortOptions={sortOptions} {...restProps} />
  </FormProvider>
);

const styles = {
  wrapper: css({
    borderTopLeftRadius: 'unset',
    borderTopRightRadius: 'unset',
    borderTop: THEME.border.base
  })
};
