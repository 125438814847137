import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { MarketplaceAffiliateCommissionStatus } from '@/graphql';

export const MarketplaceCommissionsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      month: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      status: SearchSchema.types.enum(MarketplaceAffiliateCommissionStatus)
    })
  }
});

export type MarketplaceCommissionsSearchSchemaType = z.infer<typeof MarketplaceCommissionsSearchSchema>;
export type MarketplaceCommissionsFilterFormValues = MarketplaceCommissionsSearchSchemaType['filter'];
export type MarketplaceCommissionsFilterFormKeys = UnionOfKeys<MarketplaceCommissionsFilterFormValues>;
