import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template } from './Template';
import { useTrendingInfluencersFilter } from './hooks';

export const TrendingInfluencersFilter = () => {
  const { filter, setFilterForm } = useTrendingInfluencersFilter();

  return (
    <FilterWrapper>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
export { useTrendingInfluencersFilter };
