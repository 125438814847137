import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { Genders, InfluencerSearchSortField, InfluencerStatusForSearch, SocialAccountStatus } from '@/graphql';
import { DefaultInfluencersProps, InfluencersFilterBaseSchema, InfluencersBaseSearchStateSchema } from '../schemaTypes';

export const AllInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  keyword: true,
  genders: true,
  statuses: true,
  regionIds: true,
  countryIds: true,
  includeIds: true,
  categoryIds: true,
  keywordField: true,
  socialMedias: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  campaignDisplay: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  minCampaignsJoined: true,
  maxCampaignsJoined: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  talentAgencyOrPartnerIds: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultAllInfluencersFormValues = AllInfluencersFilterSchema.parse({
  tab: InfluencersTabType.INFLUENCERS,
  statuses: [
    InfluencerStatusForSearch.SCRAPING,
    InfluencerStatusForSearch.SIGNED_UP,
    InfluencerStatusForSearch.SIGNED_UP_EMAIL
  ]
});

export const AllInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: InfluencerSearchSortField },
  filter: {
    schema: AllInfluencersFilterSchema,
    defaultValues: defaultAllInfluencersFormValues
  }
});

export type AllInfluencersSearchSchemaType = z.infer<typeof AllInfluencersSearchSchema>;
export type AllInfluencersFilterFormValues = AllInfluencersSearchSchemaType['filter'];
export type AllInfluencersFilterFormKeys = UnionOfKeys<AllInfluencersFilterFormValues>;
export type AllInfluencersProps = DefaultInfluencersProps<InfluencerProfile>;
export interface InfluencerProfile {
  id: number;
  name: string;
  tags: readonly string[];
  xhs?: boolean;
  email?: boolean;
  avatar?: string;
  tiktok?: boolean;
  douyin?: boolean;
  twitter?: boolean;
  youtube?: boolean;
  threads?: boolean;
  facebook?: boolean;
  instagram?: boolean;
  age?: number | null;
  gender: Genders | '';
  isBrandAccount: boolean;
  followers?: number | null;
  engagement?: number | null;
  engagementRate?: number | null;
  engagementViewsRate?: number | null;
  engagementFollowersRate?: number | null;
  socialAccountId?: number | null;
  status: SocialAccountStatus | null;
  country: { id?: string; name?: string };
}
