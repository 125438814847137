import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { LIMIT_50 } from '@/shared/constants';
import { SearchSchema } from '@/shared/validations';
import { Genders, InfluencerListSortField, MarketplaceCampaignParticipateStatus } from '@/graphql';

export const MarketplaceInfluencersSearchSchema = SearchSchema.create({
  limit: LIMIT_50,
  sort: { field: InfluencerListSortField },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      minAge: SearchSchema.types.number(),
      maxAge: SearchSchema.types.number(),
      minFollowers: SearchSchema.types.number(),
      maxFollowers: SearchSchema.types.number(),
      minEngagementRate: SearchSchema.types.number(),
      maxEngagementRate: SearchSchema.types.number(),
      categoryIds: SearchSchema.types.arrayString(),
      genders: SearchSchema.types.arrayEnum(Genders),
      talentAgencyOrPartnerIds: SearchSchema.types.arrayString(),
      participateStatuses: SearchSchema.types.arrayEnum(MarketplaceCampaignParticipateStatus)
    })
  }
});

export type MarketplaceInfluencersSearchSchemaType = z.infer<typeof MarketplaceInfluencersSearchSchema>;
export type MarketplaceInfluencersFilterFormValues = MarketplaceInfluencersSearchSchemaType['filter'];
export type MarketplaceInfluencersFilterFormKeys = UnionOfKeys<MarketplaceInfluencersFilterFormValues>;
