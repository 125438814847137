import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';
import { defaultProfilePostsFilterSchema } from '../../ProfilePostsList';

export const YoutubeTagRankingSearchListSchema = SearchSchema.create({
  filter: defaultProfilePostsFilterSchema,
  sort: { field: YoutubeAnalyticsTagRankingSortOrder }
});

export type YoutubeTagRankingSearchListSchemaType = z.infer<typeof YoutubeTagRankingSearchListSchema>;
