import React, { createContext, useContext } from 'react';
import { ListWithPagination } from '@/shared/molecules';
import { SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import { useInfluencerParamsSearch } from '../hooks';
import { InfluencersContextReturnType } from '../schemaTypes';
import { useFacebookPageInfluencersList } from './hooks';
import {
  FacebookPageInfluencersProps,
  FacebookPageInfluencerProfile,
  FacebookPageInfluencersFilterFormValues,
  FacebookPageInfluencersSearchSchemaType
} from './schemaTypes';

type ContextType = InfluencersContextReturnType<
  FacebookPageInfluencerProfile,
  FacebookPageInfluencersFilterFormValues,
  SocialAccountInfluencerListSortFieldV2
>;

const Context = createContext<ContextType>({} as ContextType);

interface Props {
  value: FacebookPageInfluencersProps;
  children: React.ReactElement | Array<React.ReactElement>;
}

export const FacebookPageInfluencersProvider = ({
  children,
  value: { isTtcmCampaign, isEngagementPage, isPackagePage, getSortSelectButtonProps, getListSelectButtonProps }
}: Props) => {
  const paramsSearch = useInfluencerParamsSearch<FacebookPageInfluencersSearchSchemaType>();
  const { page, sort, limit, filter } = paramsSearch;
  const listProps = useFacebookPageInfluencersList({ page, sort, limit, filter });

  return (
    <Context.Provider
      value={{
        ...paramsSearch,
        ...listProps,
        isPackagePage,
        isTtcmCampaign,
        isEngagementPage,
        getListSelectButtonProps,
        sortSelectButtonProps: getSortSelectButtonProps?.(listProps.listRecords, listProps.totalRecords)
      }}
    >
      <ListWithPagination pagination={{ totalRecords: listProps.totalRecords }}>{children}</ListWithPagination>
    </Context.Provider>
  );
};

export const useFacebookPageInfluencersContext = () => useContext<ContextType>(Context);
