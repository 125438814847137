import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { YoutubeAnalyticsPostsSortOrder, YoutubeAnalyticsComparePostsSortOrder } from '@/graphql';

export interface InfluencerCompareAccountType {
  accountId: number;
  avatarUrl: string;
  channelName: string;
}

export const InfluencerYoutubeCompareFilterSchema = ProfilePostsFilterSchema;

export const InfluencerYoutubeCompareMainSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsPostsSortOrder },
  filter: { schema: InfluencerYoutubeCompareFilterSchema }
});
export const InfluencerYoutubeCompareOtherSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsComparePostsSortOrder },
  filter: { schema: InfluencerYoutubeCompareFilterSchema }
});

export type InfluencerYoutubeCompareMainSearchType = z.infer<typeof InfluencerYoutubeCompareMainSearchSchema>;
export type InfluencerYoutubeCompareOtherSearchType = z.infer<typeof InfluencerYoutubeCompareOtherSearchSchema>;
export type InfluencerYoutubeCompareFilterFormValues = z.infer<typeof InfluencerYoutubeCompareFilterSchema>;
