import { useTranslation } from 'react-i18next';
import { useTranslateOptions } from '@/shared/hooks';
import { TTCM_STATUS_CUSTOM_OPTIONS } from '@/shared/constants';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const TTCMStatusField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const ttcmStatusOptions = useTranslateOptions(TTCM_STATUS_CUSTOM_OPTIONS);

  return (
    <SelectorField<InfluencersFilterFormKeys>
      name="ttcmStatus"
      title={t('Selector.TTCM Status')}
      options={ttcmStatusOptions}
      singleSelectorProps={{ hideDeselectOption: true }}
      {...props}
    />
  );
};
