import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormStyle } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { TextSearchField } from '@/shared/molecules';
import { UGCPostStatus, InstagramUGCManagementBasicInfoPayload } from '@/graphql';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { UGCHashTagsFilter, UGCHashtagsType, UGCPostSort, UGCPostStatusFilter } from '../../shared';
import {
  defaultInstagramUGCPostsFilterFormValues,
  AnalyticsInstagramUGCPostsFilterFormKeys,
  AnalyticsInstagramUGCPostsFilterFormValues
} from './schemaTypes';
import { useAnalyticsInstagramUGCPostsFilterOptions, useCreateDeleteAnalyticsInstagramUGCHashtag } from './hooks';

export interface TemplateProps {
  onSubmit?: () => void;
  analyticsAccount: AnalyticsSelectedAccountInfo;
  basicInfo: InstagramUGCManagementBasicInfoPayload;
}

export const Template = ({ onSubmit, basicInfo, analyticsAccount }: TemplateProps) => {
  const { t } = useTranslation();
  const { watch, setValue, reset } = useFormContext<AnalyticsInstagramUGCPostsFilterFormValues>();
  const { hashtagOptions, sortOptions, statusOptions } = useAnalyticsInstagramUGCPostsFilterOptions({ basicInfo });
  const { creating, deleting, handleAddNewHashtag, handleDeleteHashtag } = useCreateDeleteAnalyticsInstagramUGCHashtag({
    analyticsAccount
  });

  const values = watch();

  const handleSelectStatus = (status: UGCPostStatus | '') => {
    setValue('status', status);
    onSubmit?.();
  };

  const handleHashtagChange = (hashtag: UGCHashtagsType | null) => {
    // If user unselect a hashtag, reset status only, keep filter for other cases until being deleted/changed
    reset({
      ...values,
      hashtagId: hashtag?.id || defaultInstagramUGCPostsFilterFormValues.hashtagId,
      postType: hashtag?.postType || defaultInstagramUGCPostsFilterFormValues.postType,
      status: !hashtag ? defaultInstagramUGCPostsFilterFormValues.status : values.status
    });
    onSubmit?.();
  };

  return (
    <>
      <p css={{ fontWeight: 600, fontSize: '18px' }}>{t('UGC.Title')}</p>
      <p css={{ fontSize: '12px', color: THEME.text.colors.gray.lv3, marginTop: '8px' }}>
        {t('UGC.Description', { max: 5 })}
      </p>
      <UGCHashTagsFilter
        hashtags={hashtagOptions}
        activeId={values.hashtagId}
        loading={creating || deleting}
        onCreateHashtag={handleAddNewHashtag}
        onDeleteHashtag={handleDeleteHashtag}
        onHashtagChange={handleHashtagChange}
        brandInstagramUserName={basicInfo.brandInstagramUserName}
      />

      <FormStyle.Wrapper css={{ marginTop: '16px' }} gapRow={16} gapColumn={8}>
        <FormStyle.FieldsGroup>
          <UGCPostStatusFilter options={statusOptions} activeStatus={values.status} onChange={handleSelectStatus} />

          <TextSearchField<AnalyticsInstagramUGCPostsFilterFormKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('Search.UGC Search')}
          />

          <UGCPostSort
            options={sortOptions}
            sort={{ field: values.field, order: values.order }}
            onChange={({ order, field }) => {
              setValue('field', field);
              setValue('order', order);
              onSubmit?.();
            }}
          />
        </FormStyle.FieldsGroup>
      </FormStyle.Wrapper>
    </>
  );
};
