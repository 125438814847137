import { FilterAction } from '@/shared/types';
import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { Template } from './Template';
import { AdCampaignReportFilterFormValues } from './schemaTypes';

type AdCampaignReportFilterProps = FilterAction<AdCampaignReportFilterFormValues>;

export const AdCampaignReportFilter = ({ filter, setFilter }: AdCampaignReportFilterProps) => (
  <FilterWrapper>
    <FilterProvider onSubmit={setFilter} initialValues={filter} basicFilter={<Template />} />
  </FilterWrapper>
);

export * from './hooks';
export * from './schemaTypes';
