import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';
import { FormStyle } from '@/shared/atoms';
import { usePostsInsightStatusOptions } from '@/shared/hooks';
import { SelectorField, TextSearchField } from '@/shared/molecules';
import { formatIntNumber } from '@/shared/utils';

import { PostInsightStatus } from '../PostInsightStatus';

import { PostsInsightFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  totalConfirmed?: number;
  totalUnconfirmed?: number;
}

export const Template = ({ totalConfirmed, totalUnconfirmed, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { postsInsightStatusOptions } = usePostsInsightStatusOptions();

  return (
    <FormStyle.Wrapper css={{ padding: '8px 16px', borderRadius: '3px 3px 0 0' }} gapRow={16} gapColumn={8}>
      <FormStyle.FieldsGroup css={{ alignItems: 'center' }}>
        <FormStyle.FieldWrapper css={{ display: 'flex', alignItems: 'center' }}>
          <h2 css={styles.title}>{t('Report Insight Check')}</h2>

          <div css={styles.count}>
            <div>{formatIntNumber(totalConfirmed)}</div>
            <PostInsightStatus
              css={{ fontSize: '10px', height: '16px' }}
              status={EngagementCampaignPostStatusForInsightCheck.CONFIRMED}
            />
          </div>

          <div css={styles.count}>
            <div>{formatIntNumber(totalUnconfirmed)}</div>
            <PostInsightStatus
              css={{ fontSize: '10px', height: '16px' }}
              status={EngagementCampaignPostStatusForInsightCheck.UNCONFIRMED}
            />
          </div>
        </FormStyle.FieldWrapper>

        <SelectorField<PostsInsightFilterFormKeys>
          name="status"
          onSubmit={onSubmit}
          options={postsInsightStatusOptions}
          placeholder={t<string>('Selector.Status')}
          css={{ maxWidth: '194px', marginLeft: '14px' }}
        />
      </FormStyle.FieldsGroup>

      <TextSearchField<PostsInsightFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.PostsInsightFilter')}
      />
    </FormStyle.Wrapper>
  );
};
const styles = {
  title: css({ fontWeight: 600, fontSize: '18px', flex: '1 1 auto' }),
  count: css({
    marginLeft: '13px',
    textAlign: 'center',
    '& > div:first-of-type': { fontWeight: 600, fontSize: '16px', marginBottom: '4px' }
  })
};
