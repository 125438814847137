import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';

export const PostsInsightSearchFilterSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      status: SearchSchema.types.enum(EngagementCampaignPostStatusForInsightCheck)
    })
  }
});

export type PostsInsightSearchFilterSchemaType = z.infer<typeof PostsInsightSearchFilterSchema>;
export type PostsInsightFilterFormValues = PostsInsightSearchFilterSchemaType['filter'];
export type PostsInsightFilterFormKeys = UnionOfKeys<PostsInsightFilterFormValues>;
