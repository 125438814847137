import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { Order, SocialAccountStatus, SocialAccountType, SponsoredPostSortField } from '@/graphql';

export const InfluencerSponsoredPostsListSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      name: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      sm: SearchSchema.types.enum(SocialAccountType),
      status: SearchSchema.types.enum(SocialAccountStatus)
    }),
    defaultValues: { sm: SocialAccountType.INSTAGRAM }
  },
  sort: {
    field: SponsoredPostSortField,
    defaultValues: { field: SponsoredPostSortField.POST_DATE, order: Order.DESC }
  }
});

export type InfluencerSponsoredPostsListSearchSchemaType = z.infer<typeof InfluencerSponsoredPostsListSearchSchema>;
