import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export const YtVideosSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsPostsSortOrder },
  filter: { schema: z.object({ keyword: SearchSchema.types.string(), name: SearchSchema.types.string() }) }
});

export type YtVideosSearchSchemaType = z.infer<typeof YtVideosSearchSchema>;
