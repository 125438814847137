import { useTranslation } from 'react-i18next';
import { useAllTalentAgencyOrPartnerOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const TalentAgencyPartnerField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allTalentAgencyPartnerOptions } = useAllTalentAgencyOrPartnerOptions();

  return (
    <SelectorField<InfluencersFilterFormKeys>
      name="talentAgencyOrPartnerIds"
      title={t('Selector.Partner & Talent Agency')}
      options={allTalentAgencyPartnerOptions}
      multiple
      {...props}
    />
  );
};
