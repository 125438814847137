import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const AutoManagedJoinedInfluencersSearchSchema = SearchSchema.create({
  filter: { schema: z.object({ keyword: SearchSchema.types.string() }) }
});

export type AutoManagedJoinedInfluencersSearchSchemaType = z.infer<typeof AutoManagedJoinedInfluencersSearchSchema>;
export type AutoManagedJoinedInfluencersFilterFormValues = AutoManagedJoinedInfluencersSearchSchemaType['filter'];
export type AutoManagedJoinedInfluencersFilterFormKeys = UnionOfKeys<AutoManagedJoinedInfluencersFilterFormValues>;
