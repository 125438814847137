import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { InstagramFeedPostSortField, InstagramReelPostSortField, InstagramStoryPostSortField } from '@/graphql';

const AnalyticsPostsListBaseSchema = z.object({ accountId: z.number().optional() }).merge(ProfilePostsFilterSchema);

const defaultAnalyticsPostsListFilterSchema: Parameters<
  typeof SearchSchema.create<z.ZodObject<typeof AnalyticsPostsListBaseSchema.shape>, any, any>
>[0]['filter'] = {
  schema: AnalyticsPostsListBaseSchema,
  defaultValues: { startDate: getThirtyDaysAgo(), endDate: getToday() }
};

// TODO: move the types into the corresponding folder
export const AnalyticsIgFeedPostsListSearchSchema = SearchSchema.create({
  filter: defaultAnalyticsPostsListFilterSchema,
  sort: { field: InstagramFeedPostSortField }
});
export const AnalyticsIgReelPostsListSearchSchema = SearchSchema.create({
  filter: defaultAnalyticsPostsListFilterSchema,
  sort: { field: InstagramReelPostSortField }
});
export const AnalyticsIgStoryPostsListSearchSchema = SearchSchema.create({
  filter: defaultAnalyticsPostsListFilterSchema,
  sort: { field: InstagramStoryPostSortField }
});

export type AnalyticsIgFeedPostsListSearchSchemaType = z.infer<typeof AnalyticsIgFeedPostsListSearchSchema>;
export type AnalyticsIgReelPostsListSearchSchemaType = z.infer<typeof AnalyticsIgReelPostsListSearchSchema>;
export type AnalyticsIgStoryPostsListSearchSchemaType = z.infer<typeof AnalyticsIgStoryPostsListSearchSchema>;
