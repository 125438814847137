import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';

export const AnalyticsYoutubeCommentsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      brandAccountName: SearchSchema.types.string()
    })
  }
});

export type AnalyticsYoutubeCommentsSearchSchemaType = z.infer<typeof AnalyticsYoutubeCommentsSearchSchema>;
