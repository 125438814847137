import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { DefaultInfluencersProps, InfluencersBaseSearchStateSchema, InfluencersFilterBaseSchema } from '../schemaTypes';

export const CampaignInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  keyword: true,
  countryIds: true,
  campaignCategoryIds: true
});
export const CampaignInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  filter: { schema: CampaignInfluencersFilterSchema }
});

export type CampaignInfluencersSearchSchemaType = z.infer<typeof CampaignInfluencersSearchSchema>;
export type CampaignInfluencersFilterFormValues = CampaignInfluencersSearchSchemaType['filter'];
export type CampaignInfluencersFilterFormKeys = UnionOfKeys<CampaignInfluencersFilterFormValues>;
export interface CampaignInfluencersProps extends DefaultInfluencersProps<CampaignInfluencersFilterFormValues> {
  campaignId: number;
}
