import { z } from 'zod';
import { LIMIT_50 } from '@/shared/constants';
import { SearchSchema } from '@/shared/validations';
import { CampaignReportOrderField } from '@/graphql';
import { PostReportMode, UnionOfKeys } from '@/shared/types';

export const PostReportFilterSchema = z.object({
  keyword: SearchSchema.types.string(),
  mode: SearchSchema.types.enum(PostReportMode),
  endDate: SearchSchema.types.string().optional(),
  startDate: SearchSchema.types.string().optional(),
});

export const PostReportSearchSchema = SearchSchema.create({
  limit: LIMIT_50,
  sort: { field: CampaignReportOrderField },
  filter: {
    schema: PostReportFilterSchema,
    defaultValues: {
      endDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
      startDate: undefined,
      mode: PostReportMode.GRID
    }
  },
  state: {
    schema: z.object({
      post: SearchSchema.types.boolean(),
      total: SearchSchema.types.boolean(),
      influencer: SearchSchema.types.boolean()
    })
  }
});

export type PostReportSearchSchemaType = z.infer<typeof PostReportSearchSchema>;
export type PostReportFilterFormValues = PostReportSearchSchemaType['filter'];
export type PostReportFilterFormKeys = UnionOfKeys<PostReportFilterFormValues>;
