import { useTranslation } from 'react-i18next';
import { InfluencerYoutubeCompareAccountOverview } from '@/graphql';
import {
  getCompareAccountColorOpacity,
  supportedCompareAccountColors,
  AnalyticsCompareOverviewChartProps,
  AnalyticsYoutubeComparableAccountType
} from '@/shared/organisms';
import { InfluencerYoutubeCompareGraphKeys as Keys } from '../schemaTypes';

interface Props {
  selectedLegendKeys: Keys[];
  visibleAccountIds: number[];
  data: InfluencerYoutubeCompareAccountOverview[];
  comparableAccounts: AnalyticsYoutubeComparableAccountType[];
}

interface ChartValuesType {
  categories: string[];
  postsCount: number[];
  likesCount: number[];
  viewsCount: number[];
  commentsCount: number[];
  dislikesCount: number[];
  subscribersCount: number[];
}
const defaultChartValues: ChartValuesType = {
  categories: [],
  postsCount: [],
  likesCount: [],
  viewsCount: [],
  commentsCount: [],
  dislikesCount: [],
  subscribersCount: []
};

export const useInfluencerYoutubeCompareOverviewData = ({
  data,
  visibleAccountIds,
  selectedLegendKeys,
  comparableAccounts
}: Props) => {
  const { t } = useTranslation();

  let allCategories: string[] = [];
  const comparableAccountsData = data.filter((account) => visibleAccountIds.includes(account.socialAccountId));
  const chartData = comparableAccountsData.reduce<AnalyticsCompareOverviewChartProps<Keys>['data']>(
    (acc, { socialAccountId, graph: { subscribers, posts, views, likes, comments, dislikes } }) => {
      const { categories, postsCount, likesCount, viewsCount, commentsCount, dislikesCount, subscribersCount } = (
        subscribers || []
      ).reduce<ChartValuesType>((acc, curr) => {
        const date = curr.date || '';

        return {
          categories: [...acc.categories, date],
          subscribersCount: [...acc.subscribersCount, Number(curr.count)],
          likesCount: [...acc.likesCount, likes.find((item) => item.date === date)?.count || 0],
          postsCount: [...acc.postsCount, posts.find((item) => item.date === date)?.count || 0],
          viewsCount: [...acc.viewsCount, views.find((item) => item.date === date)?.count || 0],
          commentsCount: [...acc.commentsCount, comments.find((item) => item.date === date)?.count || 0],
          dislikesCount: [...acc.dislikesCount, dislikes.find((item) => item.date === date)?.count || 0]
        };
      }, defaultChartValues);

      if (!allCategories.length) {
        allCategories = [...categories];
      }

      const color = supportedCompareAccountColors.at(visibleAccountIds.indexOf(socialAccountId)) || '';
      const colorOpacity = getCompareAccountColorOpacity(color);
      const comparableAccount = comparableAccounts.find((account) => account.id === socialAccountId);

      acc.push({
        account: { name: comparableAccount?.username, avatar: comparableAccount?.avatarUrl },
        data: {
          subscribers: {
            color,
            zIndex: 1,
            type: 'line',
            showIncrement: true,
            data: subscribersCount,
            name: t<string>('Subscribers'),
            visible: selectedLegendKeys.includes('subscribers'),
            marker: {
              lineWidth: 2,
              symbol: 'circle',
              fillColor: '#fff',
              lineColor: undefined // inherit from chart
            }
          },
          posts: {
            yAxis: 1,
            type: 'column',
            data: postsCount,
            color: colorOpacity,
            name: t<string>('Post'),
            visible: selectedLegendKeys.includes('posts')
          },
          views: {
            yAxis: 2,
            type: 'column',
            data: viewsCount,
            color: colorOpacity,
            name: t<string>('View'),
            visible: selectedLegendKeys.includes('views')
          },
          likes: {
            yAxis: 3,
            type: 'column',
            data: likesCount,
            color: colorOpacity,
            name: t<string>('Like'),
            visible: selectedLegendKeys.includes('likes')
          },
          dislikes: {
            yAxis: 4,
            type: 'column',
            data: dislikesCount,
            color: colorOpacity,
            name: t<string>('Dislike'),
            visible: selectedLegendKeys.includes('dislikes')
          },
          comments: {
            yAxis: 5,
            type: 'column',
            data: commentsCount,
            color: colorOpacity,
            name: t<string>('Comment'),
            visible: selectedLegendKeys.includes('comments')
          }
        }
      });

      return acc;
    },
    []
  );

  return { chartData, allCategories };
};
