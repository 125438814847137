import { z } from 'zod';
import { SocialAccountType } from '@/graphql';
import { SearchSchema } from '@/shared/validations';
import { EngagementPostsFilterSchema } from '@/shared/organisms';

export const EggPostSearchSchema = SearchSchema.create({
  filter: {
    schema: z
      .object({
        id: SearchSchema.types.number(),
        name: SearchSchema.types.string(),
        socialAccountId: SearchSchema.types.number(),
        socialAccountName: SearchSchema.types.string(),
        socialAccountMedia: SearchSchema.types.enum(SocialAccountType)
      })
      .merge(EngagementPostsFilterSchema)
  }
});

export type EggPostSearchSchemaType = z.infer<typeof EggPostSearchSchema>;
