import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { EngagementDetail, InfluencerSearchSortField, ReadPackageAccount, ReadPackageInfluencers } from '@/graphql';
import { WidgetSelectButtonProps } from '../shared';
import { DefaultInfluencersProps, InfluencersFilterBaseSchema, InfluencersBaseSearchStateSchema } from '../schemaTypes';

export const InfluencerPackagesFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  keyword: true,
  countryIds: true
});
export const InfluencerPackagesSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: InfluencerSearchSortField },
  filter: { schema: InfluencerPackagesFilterSchema }
});

export type InfluencerPackagesSearchSchemaType = z.infer<typeof InfluencerPackagesSearchSchema>;
export type InfluencerPackagesFilterFormValues = InfluencerPackagesSearchSchemaType['filter'];
export type InfluencerPackagesFilterFormKeys = UnionOfKeys<InfluencerPackagesFilterFormValues>;

export interface InfluencerPackagesProps extends DefaultInfluencersProps<ReadPackageAccount> {
  // Currently Influencers packages list only used in Engagement page so `engagementId` much has value
  // Other Influencers search list can be use in both Engagement & Influencers page, so we don't move this prop into common interface
  engagementId: EngagementDetail['id'];
  getInfluencersListSelectButtonProps?: (
    packageAccount: ReadPackageAccount,
    influencer: ReadPackageInfluencers
  ) => WidgetSelectButtonProps;
}
