import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { SupportedSocialMediaType } from '@/shared/types';
import { SocialAccountType, XhsPostType } from '@/graphql';
import { YtTagRankingFilterSchema, YtTagRankingSearchSchema } from '../ProfilePostsList';
import { InfluencerPromotionMethodsType } from './types';

export const InfluencerAccountSearchSchema = SearchSchema.create({
  filter: {
    schema: z
      .object({
        id: SearchSchema.types.string(),
        lastUpdated: SearchSchema.types.string(),
        sm: SearchSchema.types
          .enum(SupportedSocialMediaType)
          .or(SearchSchema.types.enum(InfluencerPromotionMethodsType))
          .or(SearchSchema.types.enum(SocialAccountType)),
        xhsPostType: SearchSchema.types.enum(XhsPostType)
      })
      .merge(YtTagRankingFilterSchema),
    defaultValues: { xhsPostType: XhsPostType.NORMAL }
  }
}).merge(YtTagRankingSearchSchema.pick({ sort: true, limit: true, page: true }));

export type InfluencerAccountSearchSchemaType = z.infer<typeof InfluencerAccountSearchSchema>;
