import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { AutoManagedCampaignType } from '@/graphql';
import { SearchSchema } from '@/shared/validations';

export const MarketplaceAutoManagedCampaignSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      minPrice: SearchSchema.types.number(),
      maxPrice: SearchSchema.types.number(),
      countryId: SearchSchema.types.string(),
      categoryIds: SearchSchema.types.arrayString(),
      minCommissionRate: SearchSchema.types.number(),
      maxCommissionRate: SearchSchema.types.number(),
      marketplaceType: SearchSchema.types.enum(AutoManagedCampaignType)
    })
  }
});

export type MarketplaceAutoManagedCampaignSearchSchemaType = z.infer<typeof MarketplaceAutoManagedCampaignSearchSchema>;
export type MarketplaceAutoManagedCampaignFilterFormValues = MarketplaceAutoManagedCampaignSearchSchemaType['filter'];
export type MarketplaceAutoManagedCampaignFilterKeys = UnionOfKeys<MarketplaceAutoManagedCampaignFilterFormValues>;
