import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@/shared/utils';
import { RangeSliderInputFieldProps, RangeSliderInputField } from '@/shared/molecules';
import { MIN_MAX_FOLLOWERS_OPTIONS, MIN_FOLLOWERS, MAX_FOLLOWERS } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../schemaTypes';

interface FollowerRangeSlideFieldProps {
  minField?: Omit<RangeSliderInputFieldProps['minField'], 'name'>;
  maxField?: Omit<RangeSliderInputFieldProps['maxField'], 'name'>;
}

export const FollowerField = ({ minField, maxField }: FollowerRangeSlideFieldProps) => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={10000}
      formatLabel={formatIntNumber}
      options={MIN_MAX_FOLLOWERS_OPTIONS}
      title={t('General.Number of Followers')}
      range={{ min: MIN_FOLLOWERS, max: MAX_FOLLOWERS }}
      minField={{ ...minField, name: 'minFollowers', placeholder: formatIntNumber(MIN_FOLLOWERS) }}
      maxField={{ ...maxField, name: 'maxFollowers', placeholder: `${formatIntNumber(MAX_FOLLOWERS)}+` }}
    />
  );
};
