import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { Template } from './Template';
import { useReportFilter } from './hooks';

export const ReportFilter = () => {
  const { filter, setFilterForm } = useReportFilter();

  return (
    <FilterWrapper>
      <FilterProvider onSubmit={setFilterForm} initialValues={filter} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export * from './schemaTypes';
export { useReportFilter };
