import { z } from 'zod';
import { getToday } from '@/shared/utils';
import { getThirtyDaysAgo } from '@/shared/utils';
import { SearchSchema } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export const InfluencerYoutubeShortsSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsPostsSortOrder },
  filter: {
    schema: z.object({
      name: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      endDate: SearchSchema.types.string(),
      startDate: SearchSchema.types.string()
    }),
    defaultValues: { endDate: getToday(), startDate: getThirtyDaysAgo() }
  }
});

export type InfluencerYoutubeShortsSearchSchemaType = z.infer<typeof InfluencerYoutubeShortsSearchSchema>;
