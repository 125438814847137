import { z } from 'zod';
import { PostReportMode } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { TikTokSpecialReportOrderField } from '@/graphql';
import { PostReportFilterSchema } from '@/shared/molecules';

export const TikTokPostReportSearchSchema = SearchSchema.create({
  sort: { field: TikTokSpecialReportOrderField },
  filter: {
    schema: PostReportFilterSchema,
    defaultValues: {
      endDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
      startDate: undefined,
      mode: PostReportMode.GRID
    }
  }
});

export type TikTokPostReportSearchSchemaType = z.infer<typeof TikTokPostReportSearchSchema>;
