import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicFilterWrapper } from '@/shared/molecules';
import { getNumberValidFilter } from '@/shared/utils';
import { CountryField, GenderField, KeywordField, InfluencerTabField } from '../../shared';
import { BasicFilterDefaultProps } from '../../schemaTypes';
import { DouyinInfluencersFilterFormKeys, DouyinInfluencersFilterFormValues } from '../schemaTypes';
import { DouyinMoreFilter } from './AdvancedFilter';

export const BasicFilter = ({ onSubmit, isEngagementPage, isPackagePage }: BasicFilterDefaultProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<DouyinInfluencersFilterFormValues>();

  const values = watch();
  const numOfValidFilter = getNumberValidFilter<DouyinInfluencersFilterFormKeys>(values, [
    'tab',
    'keyword',
    'genders',
    'includeIds',
    'countryIds'
  ]);

  return (
    <BasicFilterWrapper>
      <InfluencerTabField isEngagementPage={isEngagementPage} isPackagePage={isPackagePage} onSubmit={onSubmit} />
      <KeywordField placeholder={t<string>('Search.InfluencerSocialFilter')} onSubmit={onSubmit} searchByCategories />
      <GenderField onSubmit={onSubmit} css={{ maxWidth: '123px' }} />
      <CountryField onSubmit={onSubmit} css={{ maxWidth: '138px' }} disabled multiple />
      <DouyinMoreFilter count={numOfValidFilter} onSubmit={onSubmit} isEngagementPage={isEngagementPage} />
    </BasicFilterWrapper>
  );
};
