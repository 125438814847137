import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { TikTokSpecialCampaignPostStatus } from '@/graphql';

export const TikTokCampaignPostsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      status: SearchSchema.types.enum(TikTokSpecialCampaignPostStatus)
    })
  }
});

export type TikTokCampaignPostsSearchSchemaType = z.infer<typeof TikTokCampaignPostsSearchSchema>;
export type TikTokCampaignPostsFilterFormValues = TikTokCampaignPostsSearchSchemaType['filter'];
export type TikTokCampaignPostsFilterFormKeys = UnionOfKeys<TikTokCampaignPostsFilterFormValues>;
