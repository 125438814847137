import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';
import { defaultProfilePostsFilterSchema } from '@/shared/organisms';

export const AnalyticsYtVideosSearchSchema = SearchSchema.create({
  filter: defaultProfilePostsFilterSchema,
  sort: { field: YoutubeAnalyticsPostsSortOrder }
});

export type AnalyticsYtVideosSearchSchemaType = z.infer<typeof AnalyticsYtVideosSearchSchema>;
