import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { CampaignIdentifierType, CampaignPostSocialType, CampaignPostSortField } from '@/graphql';

export const CampaignPostsDiscoverySearchSchema = SearchSchema.create({
  sort: { field: CampaignPostSortField },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      endDate: SearchSchema.types.string(),
      startDate: SearchSchema.types.string(),
      campaigns: SearchSchema.types.arrayString(),
      countryIds: SearchSchema.types.arrayString(),
      categoryIds: SearchSchema.types.arrayString(),
      influencerIds: SearchSchema.types.arrayString(),
      advertiserIds: SearchSchema.types.arrayString(),
      campaignTypes: SearchSchema.types.arrayEnum(CampaignIdentifierType),
      socialPostTypes: SearchSchema.types.arrayEnum(CampaignPostSocialType)
    })
  }
});

export type CampaignPostsDiscoverySearchType = z.infer<typeof CampaignPostsDiscoverySearchSchema>;
export type CampaignPostsDiscoveryFilterFormValues = CampaignPostsDiscoverySearchType['filter'];
export type CampaignPostsDiscoveryFilterFormKeys = UnionOfKeys<CampaignPostsDiscoveryFilterFormValues>;
