import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { getYesterday } from '@/shared/utils';
import { AnalyticsProfileFilterSchema } from '../../../schemaTypes';

export const AnalyticsTrendingYoutubePostsSearchSchema = SearchSchema.create({
  filter: {
    schema: z
      .object({
        date: SearchSchema.types.string(),
        country: SearchSchema.types.string(),
        category: SearchSchema.types.string()
      })
      .merge(AnalyticsProfileFilterSchema),
    defaultValues: { category: '24', date: getYesterday() }
  }
});

export type AnalyticsTrendingYoutubePostsSearchSchemaType = z.infer<typeof AnalyticsTrendingYoutubePostsSearchSchema>;
export type AnalyticsTrendingYoutubePostsFilterFormValues = AnalyticsTrendingYoutubePostsSearchSchemaType['filter'];
export type AnalyticsTrendingYoutubePostsFilterFormKeys = UnionOfKeys<AnalyticsTrendingYoutubePostsFilterFormValues>;
