import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { TikTokAdReportOrderField, TikTokAdReportType, TikTokAdStatus } from '@/graphql';

export const AdCampaignReportSearchSchema = SearchSchema.create({
  sort: { field: TikTokAdReportOrderField },
  filter: {
    schema: z.object({
      endDate: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      startDate: SearchSchema.types.string(),
      adGroupId: SearchSchema.types.string(),
      adCampaignId: SearchSchema.types.string(),
      status: SearchSchema.types.enum(TikTokAdStatus),
      adReportType: SearchSchema.types.enum(TikTokAdReportType)
    }),
    defaultValues: { endDate: getToday(), startDate: getThirtyDaysAgo() }
  }
});

export type AdCampaignReportSearchSchemaType = z.infer<typeof AdCampaignReportSearchSchema>;
export type AdCampaignReportFilterFormValues = AdCampaignReportSearchSchemaType['filter'];
export type AdCampaignReportFilterFormKeys = UnionOfKeys<AdCampaignReportFilterFormValues>;
