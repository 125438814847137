import { z } from 'zod';
import { Order } from '@/graphql';
import { INITIAL_PAGE_NUMBER, LIMIT } from '@/shared/constants';
import { CreateSearchSchemaOutput, CreateSearchSchemaProps } from './types';

// We can't export variable named enum/number... So we export an object instead
// We don't set any error for the filter
// We're using `.catch` to avoid failing parse from search param
// DON'T OVERRIDE the default values where validation used
const searchTypes = {
  string: () => z.coerce.string().default('').catch(''),
  boolean: () => z.coerce.boolean().default(false).catch(false),
  arrayString: () => z.array(z.coerce.string()).default([]).catch([]),
  arrayNumber: () => z.array(z.coerce.number()).default([]).catch([]),
  number: () => z.literal('').or(z.coerce.number()).default('').catch(''),
  arrayEnum: <T extends z.EnumLike>(type: T) => z.array(z.nativeEnum(type)).default([]).catch([]),
  enum: <T extends z.EnumLike>(type: T) => z.nativeEnum(type).or(z.literal('')).default('').catch(''),
  sort: <T extends z.EnumLike>(field: T) =>
    z.object({
      field: z.nativeEnum(field).nullable().default(null).catch(null),
      order: z.nativeEnum(Order).nullable().default(null).catch(null)
    })
};

export const SearchSchema = {
  types: searchTypes,
  create: <F extends z.ZodObject<any>, S extends z.EnumLike | undefined, State extends z.ZodObject<any>>({
    sort,
    limit,
    filter,
    state = { schema: z.object({}) as State, defaultValues: {} as State['_output'] }
  }: CreateSearchSchemaProps<F, S, State>) =>
    z.object({
      limit: z.number().default(limit ?? LIMIT),
      page: z.number().default(INITIAL_PAGE_NUMBER),
      state: state.schema.default(state.schema.safeParse(state.defaultValues).data || {}),
      filter: filter.schema.default(filter.schema.safeParse(filter.defaultValues).data || {}),
      sort: sort?.field
        ? searchTypes
            .sort(sort.field)
            .default({ field: sort?.defaultValues?.field ?? null, order: sort?.defaultValues?.order ?? null })
        : z.object({ field: z.null(), order: z.null() }).default({ field: null, order: null })
    }) as CreateSearchSchemaOutput<F, S, State>
};
