import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { SocialAccountInfluencerListSortFieldV2, SocialAccountType, YoutubeInfluencerV3 } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const YoutubeInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  minViews: true,
  maxViews: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  audienceAge: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  audienceRatio: true,
  audienceGender: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  followerGrowthPeriod: true,
  followerGrowthPercentage: true,
  talentAgencyOrPartnerIds: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultYoutubeInfluencersFormValues = YoutubeInfluencersFilterSchema.parse({
  tab: InfluencersTabType.YOUTUBE
});

export const YoutubeInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: YoutubeInfluencersFilterSchema,
    defaultValues: defaultYoutubeInfluencersFormValues
  }
});

export type YoutubeInfluencersSearchSchemaType = z.infer<typeof YoutubeInfluencersSearchSchema>;
export type YoutubeInfluencersFilterFormValues = YoutubeInfluencersSearchSchemaType['filter'];
export type YoutubeInfluencersFilterFormKeys = UnionOfKeys<YoutubeInfluencersFilterFormValues>;
export type YoutubeInfluencersProps = DefaultInfluencersProps<YoutubeInfluencerProfile>;
export type YoutubeInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  YoutubeInfluencerV3,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'hasSimilarAccounts'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
