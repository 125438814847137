import { useTranslation } from 'react-i18next';
import { useSelectItemIds } from '@/shared/hooks';
import { AnalyticsCompareOverviewLegendType } from '@/shared/organisms';
import { InfluencerYoutubeCompareGraphKeys as Keys } from '../schemaTypes';

export const useInfluencerYoutubeCompareOverviewLegend = () => {
  const { t } = useTranslation();
  const {
    selectItem: setSelectedLegendKey,
    selectedItemIds: selectedLegendKeys,
    setSelectedItemIds: setSelectedLegendKeys
  } = useSelectItemIds<Keys>({ defaultSelectedIds: ['subscribers', 'posts'] });

  const legendOptions: AnalyticsCompareOverviewLegendType<Keys>[] = [
    { label: t<string>('Subscribers'), value: 'subscribers', type: 'checkbox' },
    { label: t<string>('Post'), value: 'posts', type: 'radio' },
    { label: t<string>('View'), value: 'views', type: 'radio' },
    { label: t<string>('Like'), value: 'likes', type: 'radio' },
    { label: t<string>('Dislike'), value: 'dislikes', type: 'radio' },
    { label: t<string>('Comment'), value: 'comments', type: 'radio' }
  ];
  const checkboxLegendKeys = legendOptions.filter((o) => o.type === 'checkbox').map((o) => o.value);

  const handleSelectLegendOption = ({ value, type }: AnalyticsCompareOverviewLegendType<Keys>) => {
    if (type === 'checkbox') {
      setSelectedLegendKey(value);
    } else {
      const selectedCheckboxKeys = selectedLegendKeys.filter((key) => checkboxLegendKeys.includes(key));
      setSelectedLegendKeys([...selectedCheckboxKeys, value]);
    }
  };

  return {
    legendOptions,
    selectedLegendKeys,
    handleSelectLegendOption
  };
};
