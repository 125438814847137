import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { EngagementCampaignPostStatusForInsightCheck } from '@/graphql';

export const PostInsightDetailsSearchFilterSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      status: SearchSchema.types.enum(EngagementCampaignPostStatusForInsightCheck)
    })
  }
});

export type PostInsightDetailsSearchFilterSchemaType = z.infer<typeof PostInsightDetailsSearchFilterSchema>;
