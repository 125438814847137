import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { AnalyticsAdvertiserPlanFilter, NetSuiteVerifiedStatus, TikTokAdvertiserPlanFilter } from '@/graphql';

export const AccountFilterSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryIds: SearchSchema.types.arrayString(),
      verifiedStatus: SearchSchema.types.enum(NetSuiteVerifiedStatus),
      tiktokPlan: SearchSchema.types.enum(TikTokAdvertiserPlanFilter),
      analyticsPlan: SearchSchema.types.enum(AnalyticsAdvertiserPlanFilter)
    })
  }
});

export type AccountFilterSearchSchemaType = z.infer<typeof AccountFilterSearchSchema>;
export type AccountFilterFormValues = AccountFilterSearchSchemaType['filter'];
export type AccountFilterFormKeys = UnionOfKeys<AccountFilterFormValues>;
