import { useParamsSearch } from '@/shared/hooks';
import { getValuableVariables } from '@/shared/utils';
import { AnalyticsCompareOverviewWrapper, InfluencerCompareYoutubeAccountsProps } from '@/shared/organisms';
import { InfluencerYoutubeCompareAccountOverview, useInfluencerYoutubeCompareOverviewQuery } from '@/graphql';
import { Template } from './Template';
import { InfluencerProfileCompareSearchSchemaType } from './schemaTypes';

export type InfluencerCompareYoutubeOverviewProps = Pick<
  InfluencerCompareYoutubeAccountsProps,
  'accounts' | 'mainAccountId' | 'hiddenAccountIds'
>;

export const InfluencerCompareYoutubeOverview = ({
  accounts,
  mainAccountId,
  hiddenAccountIds
}: InfluencerCompareYoutubeOverviewProps) => {
  const { filter } = useParamsSearch<InfluencerProfileCompareSearchSchemaType>();
  const { data, loading } = useInfluencerYoutubeCompareOverviewQuery({
    fetchPolicy: 'cache-and-network',
    variables: getValuableVariables({
      endDate: filter.endDate,
      startDate: filter.startDate,
      socialAccountId: mainAccountId
    })
  });

  const overviewData = data?.influencerYoutubeCompareOverview;

  return (
    <AnalyticsCompareOverviewWrapper
      loading={loading}
      data={overviewData}
      dataNotAvailable={!overviewData || !(overviewData.compareAccounts.length || overviewData.mainAccount)}
    >
      {({ data }) => (
        <Template
          mainAccountId={mainAccountId}
          comparableAccounts={accounts.filter((account) => !hiddenAccountIds?.includes(account.id))}
          data={[
            data.mainAccount,
            ...data.compareAccounts.reduce<InfluencerYoutubeCompareAccountOverview[]>(
              (acc, curr) =>
                curr.accountId !== data.mainAccount.socialAccountId
                  ? [...acc, { ...curr, socialAccountId: curr.accountId }]
                  : acc,
              []
            )
          ]}
        />
      )}
    </AnalyticsCompareOverviewWrapper>
  );
};

export * from './schemaTypes';
