import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getRestApiUrl } from '@/shared/utils';
import { useDownloadFile } from '@/shared/hooks';
import { DropdownOption } from '@/shared/types';
import { TikTokAdReportType } from '@/graphql';
import { AdCampaignReportFilterFormValues } from '../../AdCampaignReportFilter';

// https://bitbucket.org/%7B5938560d-63a3-4961-a575-0628f5e42b21%7D/%7B25a1f5cd-5ec2-446e-b0e2-e3cf5fccae36%7D/pull-requests/6037/overview
export type ReportGroupBy = 'daily' | 'campaign';
export interface AdCampaignDownloadReportProps {
  adReportType: TikTokAdReportType;
  /**
   * @description we can have 3 cases based on adReportType: AD_CAMPAIGN -> adCampaignIds, AD_GROUP -> adGroupIds, AD -> adIds
   */
  allItemIds: string[];
  filter: AdCampaignReportFilterFormValues;
}

export const useAdCampaignDownloadReport = ({ adReportType, allItemIds, filter }: AdCampaignDownloadReportProps) => {
  const { t } = useTranslation();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const downloadOptions: DropdownOption<ReportGroupBy>[] = [
    { label: t('Per date'), value: 'daily' },
    { label: t('Per campaign'), value: 'campaign' }
  ];

  const handleDownloadReport = async (groupBy: ReportGroupBy) => {
    const baseParams = { endDate: filter?.endDate, startDate: filter?.startDate, groupBy };
    switch (adReportType) {
      case TikTokAdReportType.AD:
        await handleDownloadFile({
          filenamePrefix: 'report_campaign',
          url: getRestApiUrl('/api/tiktok_special/ad_report/csv/'),
          params: {
            ...baseParams,
            adReportType: TikTokAdReportType.AD,
            adIds: selectedItemIds
          }
        });
        break;
      case TikTokAdReportType.AD_GROUP:
        await handleDownloadFile({
          filenamePrefix: 'report_campaign',
          url: getRestApiUrl('/api/tiktok_special/ad_report/csv/'),
          params: {
            ...baseParams,
            adReportType: TikTokAdReportType.AD_GROUP,
            adGroupIds: selectedItemIds
          }
        });
        break;
      case TikTokAdReportType.AD_CAMPAIGN:
      default:
        await handleDownloadFile({
          filenamePrefix: `report_tt_special_${groupBy}`,
          url: getRestApiUrl('tiktok_special/ad_report/csv/'),
          params: {
            ...baseParams,
            adReportType: TikTokAdReportType.AD_CAMPAIGN,
            adCampaignIds: selectedItemIds
          }
        });
    }

    setSelectedItemIds([]);
  };

  const onClickCampaignId = (id: string | string[]) => {
    if (typeof id === 'string') {
      if (selectedItemIds.includes(id)) {
        const filteredArr = selectedItemIds.filter((item) => item !== id);
        setSelectedItemIds(filteredArr);
      } else {
        setSelectedItemIds((prevState) => [...prevState, id]);
      }
    } else {
      // means select | unselect when actionBar checkbox clicked
      if (selectedItemIds.length === allItemIds.length) {
        setSelectedItemIds([]);
      } else {
        setSelectedItemIds(allItemIds);
      }
    }
  };

  return {
    downloading,
    downloadOptions,
    handleDownloadReport,
    allItemIds,
    selectedItemIds,
    onClickCampaignId
  };
};
