import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { defaultProfilePostsFilterSchema } from '@/shared/organisms';

export const AnalyticsIgBrandAmbassadorPostsSearchSchema = SearchSchema.create({
  filter: defaultProfilePostsFilterSchema
});

export type AnalyticsIgBrandAmbassadorPostsSearchSchemaType = z.infer<
  typeof AnalyticsIgBrandAmbassadorPostsSearchSchema
>;
