import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { EngagementCampaignPostStatusForSearch } from '@/graphql';

export const EngagementPostsFilterSchema = z.object({
  keyword: SearchSchema.types.string(),
  status: SearchSchema.types.enum(EngagementCampaignPostStatusForSearch)
});
export const EngagementPostsSearchSchema = SearchSchema.create({
  filter: { schema: EngagementPostsFilterSchema }
});

export type EngagementPostsSearchSchemaType = z.infer<typeof EngagementPostsSearchSchema>;
export type EngagementPostsFilterFormValues = EngagementPostsSearchSchemaType['filter'];
export type EngagementPostsFilterFormKeys = UnionOfKeys<EngagementPostsFilterFormValues>;
