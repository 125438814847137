import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { getYesterday } from '@/shared/utils';
import { LIMIT_50 } from '@/shared/constants';
import { SearchSchema } from '@/shared/validations';
import { InstagramPostSortOrder, Order } from '@/graphql';

export const AnalyticsInstagramHashtagsSearchSchema = SearchSchema.create({
  limit: LIMIT_50,
  filter: {
    schema: z.object({
      date: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      hashtagId: SearchSchema.types.string()
    }),
    defaultValues: { date: getYesterday() }
  },
  sort: {
    field: InstagramPostSortOrder,
    defaultValues: { order: Order.ASC, field: InstagramPostSortOrder.RANK }
  }
});

export type AnalyticsInstagramHashtagsSearchSchemaType = z.infer<typeof AnalyticsInstagramHashtagsSearchSchema>;
export type AnalyticsInstagramHashtagsFilterFormValues = AnalyticsInstagramHashtagsSearchSchemaType['filter'];
export type AnalyticsInstagramHashtagsFilterFormKeys = UnionOfKeys<AnalyticsInstagramHashtagsFilterFormValues>;
