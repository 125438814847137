import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { SocialAccountInfluencerListSortField, SocialAccountType, TwitterInfluencer } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const TwitterInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  talentAgencyOrPartnerIds: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultTwitterInfluencersFormValues = TwitterInfluencersFilterSchema.parse({
  tab: InfluencersTabType.TWITTER
});

export const TwitterInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortField },
  filter: {
    schema: TwitterInfluencersFilterSchema,
    defaultValues: defaultTwitterInfluencersFormValues
  }
});

export type TwitterInfluencersSearchSchemaType = z.infer<typeof TwitterInfluencersSearchSchema>;
export type TwitterInfluencersFilterFormValues = TwitterInfluencersSearchSchemaType['filter'];
export type TwitterInfluencersFilterFormKeys = UnionOfKeys<TwitterInfluencersFilterFormValues>;
export type TwitterInfluencersProps = DefaultInfluencersProps<TwitterInfluencerProfile>;
export type TwitterInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  TwitterInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'analyticsWarning'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagement'
  | 'socialAccountEngagementFollowersRate'
>;
