import { z } from 'zod';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { Genders, SocialAccountInfluencerListSortFieldV2, SocialAccountStatus, SocialAccountType } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const FacebookPageInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  minViews: true,
  maxViews: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  audienceAge: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  audienceRatio: true,
  audienceGender: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  followerGrowthPeriod: true,
  followerGrowthPercentage: true,
  talentAgencyOrPartnerIds: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultFacebookPageInfluencersFormValues = FacebookPageInfluencersFilterSchema.parse({
  tab: InfluencersTabType.FACEBOOK_PAGE
});

export const FacebookPageInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: FacebookPageInfluencersFilterSchema,
    defaultValues: defaultFacebookPageInfluencersFormValues
  }
});

export type FacebookPageInfluencersSearchSchemaType = z.infer<typeof FacebookPageInfluencersSearchSchema>;
export type FacebookPageInfluencersFilterFormValues = FacebookPageInfluencersSearchSchemaType['filter'];
export type FacebookPageInfluencersFilterFormKeys = UnionOfKeys<FacebookPageInfluencersFilterFormValues>;
export type FacebookPageInfluencersProps = DefaultInfluencersProps<FacebookPageInfluencerProfile>;
export interface FacebookPageInfluencerProfile {
  id: number;
  name: string;
  avatar?: string;
  posts: PostType[];
  age?: number | null;
  gender: Genders | '';
  likes?: number | null;
  isBrandAccount?: boolean;
  followers?: number | null;
  analyticsWarning?: boolean;
  engagementRate?: number | null;
  socialAccountId?: number | null;
  status: SocialAccountStatus | null;
  socialAccountType: SocialAccountType;
  country: { id?: string; name?: string };
}
