import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { AutoManagedCommissionStatus } from '@/graphql';

export const AutoManagedCommissionsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      month: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      status: SearchSchema.types.enum(AutoManagedCommissionStatus)
    })
  }
});

export type AutoManagedCommissionsSearchSchemaType = z.infer<typeof AutoManagedCommissionsSearchSchema>;
export type AutoManagedCommissionsFilterFormValues = AutoManagedCommissionsSearchSchemaType['filter'];
export type AutoManagedCommissionsFilterFormKeys = UnionOfKeys<AutoManagedCommissionsFilterFormValues>;
