import { useTranslation } from 'react-i18next';
import { MarketplaceCampaignPostStatusForSearch } from '@/graphql';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { Option } from '@/shared/types';
import { TikTokCampaignPostsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  totalRecords?: number;
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();

  const statusOptions: Option<MarketplaceCampaignPostStatusForSearch>[] = [
    { label: t<string>('Option.Joined'), value: MarketplaceCampaignPostStatusForSearch.JOINED },
    { label: t<string>('Option.Reviewing'), value: MarketplaceCampaignPostStatusForSearch.DRAFT },
    { label: t<string>('Option.Approved'), value: MarketplaceCampaignPostStatusForSearch.APPROVED },
    { label: t<string>('Option.Warning'), value: MarketplaceCampaignPostStatusForSearch.WARNING },
    { label: t<string>('Option.Effective'), value: MarketplaceCampaignPostStatusForSearch.EFFECTIVE },
    { label: t<string>('Option.Ineffective'), value: MarketplaceCampaignPostStatusForSearch.INEFFECTIVE }
  ];

  return (
    <BasicFilterWrapper>
      <TextSearchField<TikTokCampaignPostsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.EngagementPostFilter')}
      />

      <SelectorField<TikTokCampaignPostsFilterFormKeys>
        name="status"
        onSubmit={onSubmit}
        options={statusOptions}
        css={{ maxWidth: '188px' }}
        placeholder={t<string>('Selector.Status')}
      />
    </BasicFilterWrapper>
  );
};
