import { LinkRouterProps } from '@/router';
import { OpenAIRunSourceFrom } from '@/graphql';
import { InfluencersTabType } from '@/shared/types';
import { InfluencersBaseSearchSchemaType } from './schemaTypes';
import { AllInfluencersFilterSchema, defaultAllInfluencersFormValues } from './AllInfluencers';
import { XhsInfluencersFilterSchema } from './XhsInfluencers';
import { InfluencerPostsFilterSchema } from './InfluencerPosts';
import { TiktokInfluencersFilterSchema } from './TiktokInfluencers';
import { DouyinInfluencersFilterSchema } from './DouyinInfluencers';
import { InfluencerPackagesFilterSchema } from './InfluencerPackages';
import { YoutubeInfluencersFilterSchema } from './YoutubeInfluencers';
import { ThreadsInfluencersFilterSchema } from './ThreadsInfluencers';
import { TwitterInfluencersFilterSchema } from './TwitterInfluencers';
import { CampaignInfluencersFilterSchema } from './CampaignInfluencers';
import { FacebookInfluencersFilterSchema } from './FacebookInfluencers';
import { InstagramInfluencersFilterSchema } from './InstagramInfluencers';
import { FacebookPageInfluencersFilterSchema } from './FacebookPageInfluencers';

const engagementPaths = [
  '/engagement/$id/influencer/posts',
  '/engagement/$id/influencer/packages',
  '/engagement/$id/influencer/campaigns',
  '/engagement/$id/influencer/instagram',
  '/engagement/$id/influencer/tiktok',
  '/engagement/$id/influencer/douyin',
  '/engagement/$id/influencer/youtube',
  '/engagement/$id/influencer/twitter',
  '/engagement/$id/influencer/facebook',
  '/engagement/$id/influencer/facebook_page',
  '/engagement/$id/influencer/threads',
  '/engagement/$id/influencer/xhs',
  '/engagement/$id/influencer'
] as const satisfies LinkRouterProps['to'][];
export const getEngagementFilterTabUrl = (tab: InfluencersTabType | ''): (typeof engagementPaths)[number] => {
  switch (tab) {
    case InfluencersTabType.POST:
      return '/engagement/$id/influencer/posts';
    case InfluencersTabType.PACKAGE:
      return '/engagement/$id/influencer/packages';
    case InfluencersTabType.CAMPAIGN:
      return '/engagement/$id/influencer/campaigns';
    case InfluencersTabType.INSTAGRAM:
      return '/engagement/$id/influencer/instagram';
    case InfluencersTabType.TIKTOK:
      return '/engagement/$id/influencer/tiktok';
    case InfluencersTabType.DOUYIN:
      return '/engagement/$id/influencer/douyin';
    case InfluencersTabType.YOUTUBE:
      return '/engagement/$id/influencer/youtube';
    case InfluencersTabType.TWITTER:
      return '/engagement/$id/influencer/twitter';
    case InfluencersTabType.FACEBOOK:
      return '/engagement/$id/influencer/facebook';
    case InfluencersTabType.FACEBOOK_PAGE:
      return '/engagement/$id/influencer/facebook_page';
    case InfluencersTabType.THREADS:
      return '/engagement/$id/influencer/threads';
    case InfluencersTabType.XHS:
      return '/engagement/$id/influencer/xhs';

    default:
      return '/engagement/$id/influencer';
  }
};

const influencersPaths = [
  '/influencers/search/instagram',
  '/influencers/search/tiktok',
  '/influencers/search/douyin',
  '/influencers/search/youtube',
  '/influencers/search/twitter',
  '/influencers/search/facebook',
  '/influencers/search/facebook_page',
  '/influencers/search/threads',
  '/influencers/search/xhs',
  '/influencers/search'
] as const satisfies LinkRouterProps['to'][];
export const getInfluencersFilterTabUrl = (tab: InfluencersTabType | ''): (typeof influencersPaths)[number] => {
  switch (tab) {
    case InfluencersTabType.INSTAGRAM:
      return '/influencers/search/instagram';
    case InfluencersTabType.TIKTOK:
      return '/influencers/search/tiktok';
    case InfluencersTabType.DOUYIN:
      return '/influencers/search/douyin';
    case InfluencersTabType.YOUTUBE:
      return '/influencers/search/youtube';
    case InfluencersTabType.TWITTER:
      return '/influencers/search/twitter';
    case InfluencersTabType.FACEBOOK:
      return '/influencers/search/facebook';
    case InfluencersTabType.FACEBOOK_PAGE:
      return '/influencers/search/facebook_page';
    case InfluencersTabType.THREADS:
      return '/influencers/search/threads';
    case InfluencersTabType.XHS:
      return '/influencers/search/xhs';

    default:
      return '/influencers/search';
  }
};

const packagePaths = [
  '/package/$id/content/search/instagram',
  '/package/$id/content/search/tiktok',
  '/package/$id/content/search/douyin',
  '/package/$id/content/search/youtube',
  '/package/$id/content/search/twitter',
  '/package/$id/content/search/facebook',
  '/package/$id/content/search/facebook_page',
  '/package/$id/content/search/threads',
  '/package/$id/content/search/xhs',
  '/package/$id/content/search'
] as const satisfies LinkRouterProps['to'][];
export const getPackageFilterTabUrl = (tab: InfluencersTabType | ''): (typeof packagePaths)[number] => {
  switch (tab) {
    case InfluencersTabType.INSTAGRAM:
      return '/package/$id/content/search/instagram';
    case InfluencersTabType.TIKTOK:
      return '/package/$id/content/search/tiktok';
    case InfluencersTabType.DOUYIN:
      return '/package/$id/content/search/douyin';
    case InfluencersTabType.YOUTUBE:
      return '/package/$id/content/search/youtube';
    case InfluencersTabType.TWITTER:
      return '/package/$id/content/search/twitter';
    case InfluencersTabType.FACEBOOK:
      return '/package/$id/content/search/facebook';
    case InfluencersTabType.FACEBOOK_PAGE:
      return '/package/$id/content/search/facebook_page';
    case InfluencersTabType.THREADS:
      return '/package/$id/content/search/threads';
    case InfluencersTabType.XHS:
      return '/package/$id/content/search/xhs';
    default:
      return '/package/$id/content/search';
  }
};
export const getInfluencerFilterTabUrl = (
  tab: InfluencersTabType | '',
  isEngagementPage?: boolean,
  isPackagePage?: boolean
) => {
  if (isEngagementPage) {
    return getEngagementFilterTabUrl(tab);
  } else if (isPackagePage) {
    return getPackageFilterTabUrl(tab);
  } else {
    return getInfluencersFilterTabUrl(tab);
  }
};

export const getOpenAiSourceFrom = (tab?: InfluencersTabType | '') => {
  switch (tab) {
    case InfluencersTabType.INFLUENCERS:
      return OpenAIRunSourceFrom.ALL_INFLUENCERS_TAB;
    case InfluencersTabType.INSTAGRAM:
      return OpenAIRunSourceFrom.INSTAGRAM_TAB;
    case InfluencersTabType.TIKTOK:
      return OpenAIRunSourceFrom.TIKTOK_TAB;
    case InfluencersTabType.YOUTUBE:
      return OpenAIRunSourceFrom.YOUTUBE_TAB;
    default:
      return null;
  }
};

export const getInfluencerFilterValuesBasedOnTab = <
  T extends InfluencersBaseSearchSchemaType['filter'] = InfluencersBaseSearchSchemaType['filter']
>(
  newFilter: T
) => {
  switch (newFilter.tab) {
    case InfluencersTabType.POST:
      return InfluencerPostsFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.PACKAGE:
      return InfluencerPackagesFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.YOUTUBE:
      return YoutubeInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.TWITTER:
      return TwitterInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.THREADS:
      return ThreadsInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.CAMPAIGN:
      return CampaignInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.FACEBOOK:
      return FacebookInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.INSTAGRAM:
      return InstagramInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.FACEBOOK_PAGE:
      return FacebookPageInfluencersFilterSchema.safeParse(newFilter).data || {};
    case InfluencersTabType.TIKTOK:
      return (
        TiktokInfluencersFilterSchema.safeParse({
          ...newFilter,
          // Age group value is different from for each sns, we need to reset it
          demographicAge: '',
          demographicAgeMinRate: ''
        }).data || {}
      );
    case InfluencersTabType.DOUYIN:
      return (
        DouyinInfluencersFilterSchema.safeParse({
          ...newFilter,
          // Age group value is different from for each sns, we need to reset it
          demographicAge: '',
          demographicAgeMinRate: '',
          countryIds: ['CN'] // Fixed for Douyin
        }).data || {}
      );
    case InfluencersTabType.XHS:
      return (
        XhsInfluencersFilterSchema.safeParse({
          ...newFilter,
          // Age group value is different from for each sns, we need to reset it
          demographicAge: '',
          demographicAgeMinRate: ''
        }).data || {}
      );
    case InfluencersTabType.INFLUENCERS:
    default:
      return (
        AllInfluencersFilterSchema.safeParse({
          ...newFilter,
          statuses: defaultAllInfluencersFormValues.statuses
        }).data || {}
      );
  }
};
