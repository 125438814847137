import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const ProfilePostsFilterSchema = z.object({
  keyword: SearchSchema.types.string().optional(),
  endDate: SearchSchema.types.string().optional(),
  startDate: SearchSchema.types.string().optional(),
  brandAccountName: SearchSchema.types.string().optional()
});

export type ProfilePostsFilterFormValues = z.infer<typeof ProfilePostsFilterSchema>;
export type ProfilePostsFilterFormKeys = UnionOfKeys<ProfilePostsFilterFormValues>;
