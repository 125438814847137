import { useTranslation } from 'react-i18next';
import { BasicFilterWrapper, TextSearchField } from '@/shared/molecules';
import { AssociatedAccountFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
  keywordPlaceholder?: string;
}

export const Template = ({ onSubmit, keywordPlaceholder }: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px 16px', borderRadius: 0 }}>
      <TextSearchField<AssociatedAccountFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        css={{ maxWidth: '35%' }}
        title={t('Search.Keyword')}
        placeholder={keywordPlaceholder}
      />
    </BasicFilterWrapper>
  );
};
