import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { PaymentRequestStatus } from '@/graphql';
import { SearchSchema } from '@/shared/validations';
import { getLastDay, getThirtyDaysAgo } from '@/shared/utils';

export const PaymentsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      endDate: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      startDate: SearchSchema.types.string(),
      status: SearchSchema.types.enum(PaymentRequestStatus)
    }),
    defaultValues: { endDate: getLastDay(), startDate: getThirtyDaysAgo() }
  }
});

export type PaymentSearchSchemaType = z.infer<typeof PaymentsSearchSchema>;
export type PaymentFilterFormValues = PaymentSearchSchemaType['filter'];
export type PaymentFilterFormKeys = UnionOfKeys<PaymentFilterFormValues>;
