import { FilterProvider } from '@/shared/molecules';
import { FilterAction } from '@/shared/types';
import { Template, TemplateProps } from './Template';
import { AnalyticsInstagramUGCPostsFilterFormValues } from './schemaTypes';

export type AnalyticsInstagramUGCPostsFilterProps = TemplateProps &
  FilterAction<AnalyticsInstagramUGCPostsFilterFormValues>;

export const AnalyticsInstagramUGCPostsFilter = ({
  filter,
  setFilter,
  ...restProps
}: AnalyticsInstagramUGCPostsFilterProps) => (
  <FilterProvider initialValues={filter} onSubmit={setFilter} basicFilter={<Template {...restProps} />} />
);

export * from './schemaTypes';
