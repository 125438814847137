import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { MarketplaceCampaignDetailType, MarketplaceCampaignStatus } from '@/graphql';

export const MarketplaceCampaignsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      status: SearchSchema.types.enum(MarketplaceCampaignStatus),
      campaignDetailType: SearchSchema.types.enum(MarketplaceCampaignDetailType)
    })
  }
});

export type MarketplaceCampaignsSearchSchemaType = z.infer<typeof MarketplaceCampaignsSearchSchema>;
export type MarketplaceCampaignsFilterFormValues = MarketplaceCampaignsSearchSchemaType['filter'];
export type MarketplaceCampaignsFilterFormKeys = UnionOfKeys<MarketplaceCampaignsFilterFormValues>;
