import { FacebookPageInfluencerV2, SocialAccountType } from '@/graphql';
import { FacebookPageInfluencerProfile } from './schemaTypes';

export const formatInfluencerProfile = (infl: FacebookPageInfluencerV2): FacebookPageInfluencerProfile => ({
  id: Number(infl.id),
  age: infl.age,
  name: infl.name,
  gender: infl.gender,
  country: infl.country,
  likes: infl.averageLikes,
  avatar: infl.avatar || '',
  status: infl.socialAccountStatus,
  isBrandAccount: infl.isBrandAccount,
  socialAccountId: Number(infl.socialAccountId),
  followers: infl.socialAccountFollowers,
  engagementRate: infl.socialAccountEngagementRate,
  socialAccountType: SocialAccountType.FACEBOOK_PAGE,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail, caption }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id),
      caption
    })) || []
});
