import { useTranslation } from 'react-i18next';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import {
  PostsSection,
  InfluencerAvatar,
  InfluencerPostDetailsModal,
  InfluencerWidgetInformation
} from '@/shared/molecules';
import { bigIntFormatter, formatPercent } from '@/shared/utils';
import { StatsSection, ProfileWidgetTemplate, SimilarInfluencersButton } from '../../shared';
import { DefaultProfileWidgetProps } from '../../schemaTypes';
import { TwitterInfluencerProfile } from '../schemaTypes';

type ProfileWidgetProps = DefaultProfileWidgetProps<TwitterInfluencerProfile>;

export const ProfileWidget = ({
  className,
  isSectionOpened,
  selectButtonProps,
  toggleSimilarInfluencersSection,
  profile: {
    id,
    age,
    name,
    posts,
    avatar,
    gender,
    country,
    averageLikes,
    isBrandAccount,
    socialAccountId,
    analyticsWarning,
    socialAccountStatus,
    socialAccountFollowers,
    socialAccountEngagement,
    socialAccountEngagementFollowersRate
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const isSignedUp = socialAccountStatus === SocialAccountStatus.SIGNED_UP;
  const stats = [
    { label: t('HeaderColumn.Followers'), value: bigIntFormatter(socialAccountFollowers || 0) },
    { label: t('HeaderColumn.ER'), value: formatPercent(socialAccountEngagementFollowersRate) },
    { label: t('HeaderColumn.Avg Engagement'), value: bigIntFormatter(socialAccountEngagement || 0) },
    { label: t('HeaderColumn.Avg Likes'), value: bigIntFormatter(averageLikes || 0, 0) }
  ];
  const profileUrl = generateProfileLink(id, {
    filter: { id: String(socialAccountId), sm: SocialAccountType.TWITTER }
  });

  return (
    <ProfileWidgetTemplate className={className} selectButtonProps={selectButtonProps}>
      <InfluencerAvatar
        size="80px"
        src={avatar}
        isBrandAccount={isBrandAccount}
        socialAccountType={SocialAccountType.TWITTER}
      />

      <InfluencerWidgetInformation
        age={age}
        name={name}
        gender={gender}
        country={country}
        profileUrl={profileUrl}
        isSignedUp={isSignedUp}
        isWarning={!!analyticsWarning}
      >
        {toggleSimilarInfluencersSection ? (
          <SimilarInfluencersButton isActive={isSectionOpened} onClick={() => toggleSimilarInfluencersSection?.()} />
        ) : null}
      </InfluencerWidgetInformation>

      <StatsSection id={id} stats={stats} />

      <PostsSection posts={posts} influencerId={id} css={{ marginLeft: '16px' }} />
      <InfluencerPostDetailsModal posts={posts} />
    </ProfileWidgetTemplate>
  );
};
