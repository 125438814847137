import { useTranslation } from 'react-i18next';
import { TextSearchField, BasicFilterWrapper, SelectorFieldV2 } from '@/shared/molecules';
import { XhsPostType } from '@/graphql';
import { InfluencerXhsPostsFilterFormKeys } from './schemaTypes';

export interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px' }}>
      <TextSearchField<InfluencerXhsPostsFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.PostsInsightFilter')}
      />
      <SelectorFieldV2<InfluencerXhsPostsFilterFormKeys>
        name="postType"
        placeholder={t<string>('Sponsored Posts')}
        onSubmit={onSubmit}
        options={[
          {
            value: XhsPostType.SPONSORED,
            label: t('Sponsored Post')
          },
          {
            value: XhsPostType.NORMAL,
            label: t('Normal Post')
          }
        ]}
        multiple
        css={{
          flex: 'none',
          width: '200px'
        }}
      />
    </BasicFilterWrapper>
  );
};
