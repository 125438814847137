import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { AnalyticsSubscriptionPlanType } from '@/graphql';

export const AnalyticsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      advertiserId: SearchSchema.types.string(),
      subscriptionPlan: SearchSchema.types.enum(AnalyticsSubscriptionPlanType)
    })
  }
});

export type AnalyticsSearchSchemaType = z.infer<typeof AnalyticsSearchSchema>;
export type AnalyticsFilterFormValues = AnalyticsSearchSchemaType['filter'];
export type AnalyticsFilterFormKeys = UnionOfKeys<AnalyticsFilterFormValues>;
