import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';

export const CheckingPlanSubscriptionSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      priceId: SearchSchema.types.number(),
      packageId: SearchSchema.types.number(),
      sessionId: SearchSchema.types.string()
    })
  }
});

export type CheckingPlanSubscriptionSearchSchemaType = z.infer<typeof CheckingPlanSubscriptionSearchSchema>;
