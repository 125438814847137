import { useTranslation } from 'react-i18next';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import {
  PostsSection,
  InfluencerAvatar,
  InfluencerPostDetailsModal,
  InfluencerWidgetInformation
} from '@/shared/molecules';
import { bigIntFormatter, formatPercent } from '@/shared/utils';
import { StatsSection, ProfileWidgetTemplate } from '../../shared';
import { DefaultProfileWidgetProps } from '../../schemaTypes';
import { DouyinInfluencerProfile } from '../schemaTypes';

type ProfileWidgetProps = DefaultProfileWidgetProps<DouyinInfluencerProfile>;

export const ProfileWidget = ({
  className,
  selectButtonProps,
  profile: {
    id,
    age,
    name,
    posts,
    avatar,
    gender,
    country,
    averageLikes,
    averageViews,
    socialAccountId,
    socialAccountStatus,
    socialAccountFollowers,
    socialAccountEngagementViewsRate,
    socialAccountEngagementFollowersRate
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const isSignedUp = socialAccountStatus === SocialAccountStatus.SIGNED_UP;
  const stats = [
    { label: t('HeaderColumn.Followers'), value: bigIntFormatter(socialAccountFollowers || 0) },
    { label: t('HeaderColumn.Egg Rate by views'), value: formatPercent(socialAccountEngagementViewsRate) },
    { label: t('HeaderColumn.Egg Rate by followers'), value: formatPercent(socialAccountEngagementFollowersRate) },
    { label: t('HeaderColumn.Avg Views'), value: bigIntFormatter(averageViews || 0, 0) },
    { label: t('HeaderColumn.Avg Likes'), value: bigIntFormatter(averageLikes || 0, 0) }
  ];
  const profileUrl = generateProfileLink(id, { filter: { id: String(socialAccountId), sm: SocialAccountType.DOUYIN } });

  return (
    <ProfileWidgetTemplate className={className} selectButtonProps={selectButtonProps}>
      <InfluencerAvatar size="80px" src={avatar} socialAccountType={SocialAccountType.DOUYIN} />

      <InfluencerWidgetInformation
        age={age}
        name={name}
        gender={gender}
        country={country}
        profileUrl={profileUrl}
        isSignedUp={isSignedUp}
      />

      <StatsSection id={id} stats={stats} />

      <PostsSection posts={posts} influencerId={Number(id)} css={{ marginLeft: '16px' }} />
      <InfluencerPostDetailsModal posts={posts} />
    </ProfileWidgetTemplate>
  );
};
