import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { FollowersGrowthPeriodEnum } from '@/graphql';

export enum TrendingInfluencersTabType {
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM'
}

export const TrendingInfluencersSearchFilterSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      countryId: z.string().default('JP'),
      minFollowers: z.string().default('10000'),
      categoryIds: z.array(z.string()).default([]),
      tab: z.nativeEnum(TrendingInfluencersTabType).default(TrendingInfluencersTabType.INSTAGRAM),
      followerGrowthPeriod: z.nativeEnum(FollowersGrowthPeriodEnum).default(FollowersGrowthPeriodEnum.ONE_MONTH)
    })
  }
});

export type TrendingInfluencersSearchFilterSchemaType = z.infer<typeof TrendingInfluencersSearchFilterSchema>;
export type TrendingInfluencersFilterFormValues = TrendingInfluencersSearchFilterSchemaType['filter'];
export type TrendingInfluencersFilterFormKeys = UnionOfKeys<TrendingInfluencersFilterFormValues>;
