import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { MarketplaceCampaignPostStatusForSearch } from '@/graphql';

export const MarketplacePostsFilterSchema = z.object({
  keyword: SearchSchema.types.string(),
  status: SearchSchema.types.enum(MarketplaceCampaignPostStatusForSearch)
});
export const MarketplacePostsSearchSchema = SearchSchema.create({
  filter: { schema: MarketplacePostsFilterSchema }
});

export type MarketplacePostsSearchSchemaTypes = z.infer<typeof MarketplacePostsSearchSchema>;
export type MarketplacePostsFilterFormValues = MarketplacePostsSearchSchemaTypes['filter'];
export type MarketplacePostsFilterFormKeys = UnionOfKeys<MarketplacePostsFilterFormValues>;
