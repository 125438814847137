import { InfluencerV4, SocialAccountStatus } from '@/graphql';
import { InfluencerProfile } from './schemaTypes';

export const formatInfluencerProfile = (infl: InfluencerV4): InfluencerProfile => ({
  id: infl.id,
  age: infl.age,
  name: infl.name,
  tags: infl.tags,
  gender: infl.gender,
  avatar: infl.avatar,
  status: infl.status,
  country: infl.country,
  socialAccountId: null,
  followers: infl.followersCount,
  isBrandAccount: infl.isBrandAccount,
  xhs: infl.xhs?.isAnySignedUpAccount,
  tiktok: infl.tiktok?.isAnySignedUpAccount,
  douyin: infl.douyin?.isAnySignedUpAccount,
  twitter: infl.twitter?.isAnySignedUpAccount,
  threads: infl.threads?.isAnySignedUpAccount,
  youtube: infl.youtube?.isAnySignedUpAccount,
  facebook: infl.facebook?.isAnySignedUpAccount,
  engagement: infl.engagement.averageEngagement,
  engagementRate: infl.engagement.engagementRate,
  engagementViewsRate: infl.engagement.engagementViewsRate,
  engagementFollowersRate: infl.engagement.engagementFollowersRate,
  instagram: infl.instagram?.isAnySignedUpAccount,
  email: infl.email === null || infl.status === SocialAccountStatus.SCRAPING ? undefined : infl.email
});
