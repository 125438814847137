import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const ReferralTrackingSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      campaign_id: SearchSchema.types.string(),
      sale_amount: SearchSchema.types.string(),
      referral_code: SearchSchema.types.string(),
      transaction_id: SearchSchema.types.string()
    })
  }
});

export type ReferralTrackingSearchSchemaType = z.infer<typeof ReferralTrackingSearchSchema>;
export type ReferralTrackingFilterFormValues = ReferralTrackingSearchSchemaType['filter'];
export type ReferralTrackingFilterFormKeys = UnionOfKeys<ReferralTrackingFilterFormValues>;
export const REFERRAL_TRACKING_PARAMS = {
  referral_code: 'referral_code',
  transaction_id: 'transaction_id',
  campaign_id: 'campaign_id',
  sale_amount: 'sale_amount'
};
