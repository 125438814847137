import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicFilterWrapper } from '@/shared/molecules';
import { getNumberValidFilter } from '@/shared/utils';
import { usePostsFilterOptions } from '@/shared/organisms';
import { GenderField, KeywordField, CountryField, SocialMediaField, InfluencerTabField } from '../../shared';
import { BasicFilterDefaultProps } from '../../schemaTypes';
import { InfluencerPostsFilterFormKeys, InfluencerPostsFilterFormValues } from '../schemaTypes';
import { AdvancedFilter } from './AdvancedFilter';

export const BasicFilter = ({ onSubmit, isEngagementPage }: BasicFilterDefaultProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<InfluencerPostsFilterFormValues>();
  const { socialMediaOptions, isCNCountrySupported, allCountryOptions } = usePostsFilterOptions();

  const values = watch();
  const numOfValidFilter = getNumberValidFilter<InfluencerPostsFilterFormKeys>(values, [
    'tab',
    'keyword',
    'genders',
    'countryId',
    'includeIds',
    'socialMedia'
  ]);

  return (
    <BasicFilterWrapper>
      <InfluencerTabField isEngagementPage={isEngagementPage} onSubmit={onSubmit} />
      <KeywordField onSubmit={onSubmit} placeholder={t<string>('Search.InfluencerPostFilter')} />
      <SocialMediaField
        title=""
        onSubmit={onSubmit}
        options={socialMediaOptions}
        css={{ flex: 'none', width: '151px' }}
      />
      <GenderField onSubmit={onSubmit} css={{ flex: 'none', width: '123px' }} />
      <CountryField
        onSubmit={onSubmit}
        options={allCountryOptions}
        css={{ flex: 'none', width: '138px' }}
        singleSelectorProps={{ hideDeselectOption: true, disabled: isCNCountrySupported }}
      />
      <AdvancedFilter count={numOfValidFilter} onSubmit={onSubmit} isEngagementPage={isEngagementPage} />
    </BasicFilterWrapper>
  );
};
