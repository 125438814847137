import { useState } from 'react';
import { useFetchDailyPostsOnChart } from '@/shared/hooks';
import {
  supportedCompareAccountColors,
  getCompareAccountColorOpacity,
  AnalyticsCompareOverviewChartProps
} from '@/shared/organisms';
import { InfluencerYoutubeCompareGraphKeys as Keys } from '../schemaTypes';

interface Props {
  chartData: AnalyticsCompareOverviewChartProps<Keys>['data'];
}

export const useInfluencerYoutubeCompareOverviewPosts = ({ chartData }: Props) => {
  const [selectedPostDay, setSelectedPostDay] = useState('');

  const { customChartOptions } = useFetchDailyPostsOnChart({
    onFetchPosts: setSelectedPostDay,
    postsData: chartData.reduce<number[][]>((acc, curr) => [...acc, curr.data.posts.data], []),
    postColors: Object.values(supportedCompareAccountColors).map((color) => getCompareAccountColorOpacity(color))
  });

  return { selectedPostDay, setSelectedPostDay, customChartOptions };
};
