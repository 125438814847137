import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { Genders, SocialAccountInfluencerListSortField } from '@/graphql';

export const TikTokCampaignInfluencersSearchSchema = SearchSchema.create({
  sort: { field: SocialAccountInfluencerListSortField },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      packageId: SearchSchema.types.number(),
      countryIds: SearchSchema.types.arrayString(),
      categoryIds: SearchSchema.types.arrayString(),
      genders: SearchSchema.types.arrayEnum(Genders)
    })
  }
});

export type TikTokCampaignInfluencersSearchType = z.infer<typeof TikTokCampaignInfluencersSearchSchema>;
export type TikTokCampaignInfluencersFilterFormValues = TikTokCampaignInfluencersSearchType['filter'];
export type TikTokCampaignInfluencersFilterFormKeys = UnionOfKeys<TikTokCampaignInfluencersFilterFormValues>;
