import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useInfluencerPermissions, useUserRoles } from '@/auth';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { ColumnProps, RowProps, TableCell } from '@/shared/atoms';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import {
  PostsSection,
  InfluencerAvatar,
  InfluencerPostDetailsModal,
  InfluencerWidgetInformation
} from '@/shared/molecules';
import { formatIntNumber, formatPercent } from '@/shared/utils';
import { TrendingInfluencersTabType } from '../../TrendingInfluencersFilter/schemaTypes';
import { TrendingInfluencerProfileType } from '../types';

interface Props {
  tab: TrendingInfluencersTabType;
  data: readonly TrendingInfluencerProfileType[];
}

export const useTrendingInfluencersTable = ({ tab, data }: Props) => {
  const { t } = useTranslation();
  const { isAdvertiser, isTalentAgency } = useUserRoles();
  const { hideEditInfluencerBtn } = useInfluencerPermissions();
  const { generateProfileLink } = useGenerateInfluencerProfileLink({
    disabled: isAdvertiser || isTalentAgency || hideEditInfluencerBtn
  });

  const isYoutube = tab === TrendingInfluencersTabType.YOUTUBE;
  const columns: ColumnProps[] = [
    { title: t('HeaderColumn.Ranking'), styles: { textAlign: 'center', paddingRight: '16px' } },
    { title: t('HeaderColumn.Influencer') },
    { title: t('HeaderColumn.Follower'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Average Views'), styles: { textAlign: 'right' }, hidden: !isYoutube },
    { title: t('HeaderColumn.ER'), styles: { textAlign: 'right' }, hidden: isYoutube },
    { title: t('HeaderColumn.Follower Growth'), styles: { textAlign: 'right' } },
    { title: t('HeaderColumn.Posts'), styles: { paddingLeft: '28px' } }
  ];
  const rows: RowProps[] = data.map((infl) => {
    const {
      id: influencerId,
      rank,
      age,
      name,
      avatar,
      gender,
      status,
      country,
      socialMedia,
      popularPosts,
      socialAccountId,
      followersGrowthRate,
      socialAccountFollowers,
      socialAccountAverageViews,
      socialAccountEngagementRate
    } = infl;
    const socialAccountType = socialMedia || (tab as unknown as SocialAccountType);
    const isSignedUp = status === SocialAccountStatus.SIGNED_UP;
    const posts = popularPosts.map(({ id, thumbNail, type }) => ({
      id,
      type,
      thumbNail,
      influencerId: Number(influencerId)
    }));

    return {
      styles: css({ '> td:last-of-type': { paddingLeft: '28px' } }),
      cells: [
        <TableCell.Text value={String(rank)} css={{ minWidth: '60px', textAlign: 'center', paddingRight: '8px' }} />,
        <div css={{ maxWidth: '300px', display: 'flex', alignItems: 'center', padding: '16px 0' }}>
          <InfluencerAvatar size="80px" src={avatar || ''} socialAccountType={socialAccountType} />
          <InfluencerWidgetInformation
            age={age}
            name={name}
            gender={gender}
            country={country}
            isSignedUp={isSignedUp}
            profileUrl={generateProfileLink(
              influencerId,
              socialAccountId ? { filter: { id: socialAccountId, sm: socialAccountType } } : undefined
            )}
          />
        </div>,
        <TableCell.Number value={formatIntNumber(socialAccountFollowers)} />,
        <TableCell.Number value={formatIntNumber(socialAccountAverageViews)} />,
        <TableCell.Number value={formatPercent(socialAccountEngagementRate, false)} unit="%" />,
        <TableCell.Number value={formatPercent(followersGrowthRate, false)} unit="%" />,
        <>
          <PostsSection influencerId={Number(influencerId)} posts={posts} />
          <InfluencerPostDetailsModal posts={posts} />
        </>
      ]
    };
  });

  return { rows, columns };
};
