import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { CheckBoxProps } from '@/shared/atoms';
import { SearchSchema } from '@/shared/validations';
import { SocialAccountType, SocialPostType, Genders, SocialAccountStatus, PostListSortFieldV3 } from '@/graphql';
import { PostType, DefaultInfluencersProps, InfluencersFilterBaseSchema } from '../schemaTypes';

export const InfluencerPostsFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  keyword: true,
  genders: true,
  minViews: true,
  maxViews: true,
  countryId: true,
  includeIds: true,
  socialMedia: true,
  categoryIds: true,
  minPostDate: true,
  maxPostDate: true,
  minFollowers: true,
  maxFollowers: true,
  minEngagementRate: true,
  maxEngagementRate: true
});
export const InfluencersPostsSearchSchema = SearchSchema.create({
  sort: { field: PostListSortFieldV3 },
  filter: { schema: InfluencerPostsFilterSchema }
});

export type InfluencersPostsSearchSchemaType = z.infer<typeof InfluencersPostsSearchSchema>;
export type InfluencerPostsFilterFormValues = InfluencersPostsSearchSchemaType['filter'];
export type InfluencerPostsFilterFormKeys = UnionOfKeys<InfluencerPostsFilterFormValues>;
export interface InfluencerPostsProps
  extends Omit<DefaultInfluencersProps<InfluencerPostProfile>, 'getSortSelectButtonProps'> {
  getSortSelectButtonProps: (
    listRecords: InfluencerPostProfile[],
    filterPostSocialMedia: SocialAccountType | SocialPostType
  ) => CheckBoxProps;
}
export interface InfluencerPostProfile {
  id: number;
  name: string;
  postDate?: string | number;
  avatar?: string;
  posts: PostType[];
  age?: number | null;
  gender: Genders | '';
  postId?: number | null;
  isBrandAccount?: boolean;
  postDescription?: string;
  followers?: number | null;
  postThumbnail?: string | null;
  engagementRate?: number | null;
  socialAccountId?: number | null;
  status?: SocialAccountStatus | null;
  socialMedia?: SocialAccountType | '';
  country: { id?: string; name?: string };
}
