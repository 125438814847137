import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const MarketplacesReorderSearchSchema = SearchSchema.create({
  filter: { schema: z.object({ countryId: SearchSchema.types.string() }) }
});

export type MarketplacesReorderSearchSchemaType = z.infer<typeof MarketplacesReorderSearchSchema>;
export type MarketplacesReorderFormValues = MarketplacesReorderSearchSchemaType['filter'];
export type MarketplacesReorderFormKeys = UnionOfKeys<MarketplacesReorderFormValues>;
