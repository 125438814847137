import { useTranslation } from 'react-i18next';
import { SocialAccountStatus, SocialAccountType } from '@/graphql';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import {
  PostsSection,
  InfluencerAvatar,
  InfluencerPostDetailsModal,
  InfluencerWidgetInformation
} from '@/shared/molecules';
import { bigIntFormatter, formatPercent } from '@/shared/utils';
import { StatsSection, ProfileWidgetTemplate, SimilarInfluencersButton } from '../../shared';
import { DefaultProfileWidgetProps } from '../../schemaTypes';
import { InstagramInfluencerProfile } from '../schemaTypes';

type ProfileWidgetProps = DefaultProfileWidgetProps<InstagramInfluencerProfile>;

export const ProfileWidget = ({
  className,
  isSectionOpened,
  selectButtonProps,
  toggleSimilarInfluencersSection,
  profile: {
    id,
    age,
    name,
    posts,
    avatar,
    gender,
    country,
    averageLikes,
    averageReach,
    isBrandAccount,
    socialAccountId,
    analyticsWarning,
    socialAccountType,
    socialAccountStatus,
    socialAccountFollowers,
    socialAccountEngagementRate
  }
}: ProfileWidgetProps) => {
  const { t } = useTranslation();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();

  const isSignedUp = socialAccountStatus === SocialAccountStatus.SIGNED_UP;
  const stats = [
    { label: t('HeaderColumn.Followers'), value: bigIntFormatter(socialAccountFollowers || 0) },
    { label: t('HeaderColumn.ER'), value: formatPercent(socialAccountEngagementRate) },
    {
      label: t(isSignedUp ? 'HeaderColumn.Avg Reach' : 'HeaderColumn.Est Reach'),
      value: bigIntFormatter(averageReach || 0)
    },
    { label: t('HeaderColumn.Avg Likes'), value: bigIntFormatter(averageLikes || 0, 0) }
  ];
  const profileUrl = generateProfileLink(id, { filter: { id: socialAccountId, sm: SocialAccountType.INSTAGRAM } });

  return (
    <ProfileWidgetTemplate className={className} selectButtonProps={selectButtonProps}>
      <InfluencerAvatar
        size="80px"
        src={avatar}
        isBrandAccount={isBrandAccount}
        socialAccountType={socialAccountType}
      />

      <InfluencerWidgetInformation
        age={age}
        name={name}
        gender={gender}
        country={country}
        profileUrl={profileUrl}
        isSignedUp={isSignedUp}
        isWarning={!!analyticsWarning}
      >
        {toggleSimilarInfluencersSection ? (
          <SimilarInfluencersButton isActive={isSectionOpened} onClick={() => toggleSimilarInfluencersSection()} />
        ) : null}
      </InfluencerWidgetInformation>

      <StatsSection id={id} stats={stats} />

      <PostsSection posts={posts} influencerId={Number(id)} css={{ marginLeft: '16px' }} />
      <InfluencerPostDetailsModal posts={posts} hasSimilarPosts />
    </ProfileWidgetTemplate>
  );
};
