import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InstagramFeedPostSortField } from '@/graphql';

export const InfluencerInstagramPostsListSearchSchema = SearchSchema.create({
  sort: { field: InstagramFeedPostSortField },
  filter: {
    schema: z.object({
      name: SearchSchema.types.string(),
      keyword: SearchSchema.types.string()
    })
  }
});

export type InfluencerInstagramPostsListSearchType = z.infer<typeof InfluencerInstagramPostsListSearchSchema>;
