import { useTranslation } from 'react-i18next';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_ENGAGEMENT_RATE, MAX_ENGAGEMENT_RATE, MIN_MAX_EN_RATE_OPTIONS } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const EngagementFollowersAndViewsRateField = () => {
  const { t } = useTranslation();

  return (
    <>
      <RangeSliderInputField<InfluencersFilterFormKeys>
        step={0.1}
        options={MIN_MAX_EN_RATE_OPTIONS}
        title={t('HeaderColumn.Egg Rate by followers')}
        range={{ min: MIN_ENGAGEMENT_RATE, max: MAX_ENGAGEMENT_RATE }}
        minField={{ name: 'minEngagementFollowersRate', placeholder: `${MIN_ENGAGEMENT_RATE}%` }}
        maxField={{ name: 'maxEngagementFollowersRate', placeholder: `${MAX_ENGAGEMENT_RATE}%` }}
      />
      <RangeSliderInputField<InfluencersFilterFormKeys>
        step={0.1}
        options={MIN_MAX_EN_RATE_OPTIONS}
        title={t('HeaderColumn.Egg Rate by views')}
        range={{ min: MIN_ENGAGEMENT_RATE, max: MAX_ENGAGEMENT_RATE }}
        minField={{ name: 'minEngagementViewsRate', placeholder: `${MIN_ENGAGEMENT_RATE}%` }}
        maxField={{ name: 'maxEngagementViewsRate', placeholder: `${MAX_ENGAGEMENT_RATE}%` }}
      />
    </>
  );
};
