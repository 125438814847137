import { zodValidator } from '@tanstack/zod-adapter';
import { Outlet, redirect } from '@tanstack/react-router';
import { Query, QuerytiktokSpecialCampaignArgs, TIKTOK_SPECIAL_CAMPAIGN_QUERY } from '@/graphql';
import { PostReportMode } from '@/shared/types';
import { getThirtyDaysAgo, getLastDay } from '@/shared/utils';
import {
  AdCampaignReportSearchSchema,
  TikTokCampaignInfluencersSearchSchema,
  TikTokCampaignPostsSearchSchema,
  TikTokCampaignsSearchSchema,
  TikTokPostReportSearchSchema
} from '@/shared/organisms';
import {
  AddTikTokCampaign,
  CheckingPlanSubscriptionSearchSchema,
  TikTokAccountDetails,
  TikTokAdAccountsCenter,
  TikTokAdGroupReport,
  TikTokAdReport,
  TikTokCampaignDetails,
  TikTokCampaignDetailsReport,
  TikTokCampaignDetailsForm,
  TikTokCampaignDetailsInfluencers,
  TiktokReviewInfluencers,
  TikTokBilling,
  TikTokCampaignEditPost,
  TikTokCampaignDetailsPosts,
  TikTokFailedPayment
} from '@/pages/TikTokAdvertiserPages';
import { PartialRouter } from '../../types';
import { AdCampaignReport, AdCampaignReportRoot } from './ad-campaign-report';
import { TiktokRoot } from './root';
import { BudgetAllocation } from './budget-allocation';
import { TikTokCampaignsComponent } from './campaigns';

export const TikTokRouter = {
  '/_private-routes/tiktok': [TiktokRoot, {}],
  '/_private-routes/tiktok/ad-accounts': [TikTokAdAccountsCenter, {}],
  '/_private-routes/tiktok/ad-campaign-report': [AdCampaignReportRoot, {}],
  '/_private-routes/tiktok/ad-campaign-report/': [
    AdCampaignReport,
    { validateSearch: zodValidator(AdCampaignReportSearchSchema) }
  ],
  '/_private-routes/tiktok/ad-campaign-report/$id/': [
    TikTokAdGroupReport,
    { validateSearch: zodValidator(AdCampaignReportSearchSchema) }
  ],
  '/_private-routes/tiktok/ad-campaign-report/$id/$groupId': [
    TikTokAdReport,
    { validateSearch: zodValidator(AdCampaignReportSearchSchema) }
  ],
  '/_private-routes/tiktok/advertiser-details': [TikTokAccountDetails, {}],
  '/_private-routes/tiktok/budget-allocation': [BudgetAllocation, {}],
  '/_private-routes/tiktok/campaigns': [() => <Outlet />, {}],
  '/_private-routes/tiktok/campaigns/': [
    TikTokCampaignsComponent,
    { validateSearch: zodValidator(TikTokCampaignsSearchSchema) }
  ],
  '/_private-routes/tiktok/campaigns/add': [
    AddTikTokCampaign,
    { validateSearch: zodValidator(CheckingPlanSubscriptionSearchSchema) }
  ],
  '/_private-routes/tiktok/campaigns/review-influencers': [
    TiktokReviewInfluencers,
    { validateSearch: zodValidator(TikTokCampaignInfluencersSearchSchema) }
  ],
  '/_private-routes/tiktok/campaigns/$id': [
    TikTokCampaignDetails,
    {
      loader: async ({ context, params }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: 0,
            errorMessage: null,
            tiktokSpecialCampaign: null
          };
        }
        const { data, loading, errors, networkStatus } = await context.queryClient.query<
          Query,
          QuerytiktokSpecialCampaignArgs
        >({
          query: TIKTOK_SPECIAL_CAMPAIGN_QUERY,
          variables: {
            id: +params.id
          },
          notifyOnNetworkStatusChange: true
        });

        return {
          loading,
          networkStatus,
          errorMessage: errors?.at(0)?.message,
          tiktokSpecialCampaign: data?.tiktokSpecialCampaign || null
        };
      }
    }
  ],
  '/_private-routes/tiktok/campaigns/$id/': [TikTokCampaignDetailsForm, {}],
  '/_private-routes/tiktok/campaigns/$id/influencers': [
    TikTokCampaignDetailsInfluencers,
    { validateSearch: zodValidator(TikTokCampaignInfluencersSearchSchema) }
  ],
  '/_private-routes/tiktok/campaigns/$id/report': [
    TikTokCampaignDetailsReport,
    {
      validateSearch: zodValidator(TikTokPostReportSearchSchema),
      loaderDeps: ({ search }) => search,
      loader: ({ params, context, deps }) => {
        if (!context.queryClient) {
          return {
            loading: true,
            networkStatus: 0,
            errorMessage: null,
            tiktokSpecialCampaign: null
          };
        }
        const data = context.queryClient.readQuery<Query, QuerytiktokSpecialCampaignArgs>({
          query: TIKTOK_SPECIAL_CAMPAIGN_QUERY,
          variables: {
            id: +params.id
          }
        });

        if (
          deps.filter.startDate === undefined &&
          deps.filter.endDate === undefined &&
          deps.filter.mode === PostReportMode.TABLE
        ) {
          throw redirect({
            replace: true,
            to: '/tiktok/campaigns/$id/report',
            params: {
              id: params.id
            },
            search: {
              filter: {
                mode: PostReportMode.TABLE,
                endDate: data?.tiktokSpecialCampaign?.endDate || getLastDay(),
                startDate: data?.tiktokSpecialCampaign?.startDate || getThirtyDaysAgo()
              }
            }
          });
        }
      }
    }
  ],
  '/_private-routes/tiktok/campaigns/$id/posts': [TikTokCampaignDetailsPosts, {}],
  '/_private-routes/tiktok/campaigns/$id/posts/': [
    () => <Outlet />,
    { validateSearch: zodValidator(TikTokCampaignPostsSearchSchema) }
  ],
  '/_private-routes/tiktok/campaigns/$id/posts/$postId': [TikTokCampaignEditPost, {}],
  '/_private-routes/tiktok/failed-payment': [TikTokFailedPayment, {}],
  '/_private-routes/tiktok/subscription-billing': [TikTokBilling, {}]
} as const satisfies PartialRouter;
