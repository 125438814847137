import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { FilterAction } from '@/shared/types';
import { Template } from './Template';
import { InfluencerProfileCampaignsFilterFormValues } from './schemaTypes';

export type CampaignListFilterProps = FilterAction<InfluencerProfileCampaignsFilterFormValues>;

export const InfluencerProfileCampaignsFilter = ({ filter, setFilter }: CampaignListFilterProps) => (
  <FilterWrapper>
    <FilterProvider onSubmit={setFilter} initialValues={filter} basicFilter={<Template />} />
  </FilterWrapper>
);

export * from './schemaTypes';
