import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export const InfluencerYtSponsoredPostsListSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsPostsSortOrder },
  filter: { schema: z.object({ keyword: SearchSchema.types.string() }) }
});

export type InfluencerYtSponsoredPostsListSearchSchemaType = z.infer<typeof InfluencerYtSponsoredPostsListSearchSchema>;
