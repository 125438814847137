import { useFormContext } from 'react-hook-form';
import { FILTER_SECTIONS, MoreFilterPopover } from '@/shared/molecules';
import {
  AgeField,
  ViewField,
  RegionField,
  FollowerField,
  PostPriceField,
  DemographicField,
  FollowerGrowthRate,
  InfluencerStatusField,
  InfluencerCategoryField,
  TalentAgencyPartnerField,
  EngagementFollowersAndViewsRateField
} from '../../shared';
import { AdvancedFilterDefaultProps } from '../../schemaTypes';
import { defaultYoutubeInfluencersFormValues, YoutubeInfluencersFilterFormValues } from '../schemaTypes';

export const AdvancedFilter = ({ count, onSubmit }: AdvancedFilterDefaultProps) => {
  const { reset, getValues } = useFormContext<YoutubeInfluencersFilterFormValues>();

  const values = getValues();

  const handleClear = () => {
    reset({
      ...defaultYoutubeInfluencersFormValues,
      // Basic filter
      keyword: values.keyword,
      genders: values.genders,
      countryIds: values.countryIds
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: (
            <>
              <AgeField />
              <InfluencerStatusField />
              <TalentAgencyPartnerField />
              <InfluencerCategoryField />
              <RegionField countryId={(values.countryIds.length === 1 && values.countryIds[0]) || ''} />
            </>
          )
        },
        {
          value: FILTER_SECTIONS.Audiences,
          title: FILTER_SECTIONS.Audiences,
          node: <DemographicField />
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <FollowerField />
              <EngagementFollowersAndViewsRateField />
              <ViewField />
              <PostPriceField />
              <FollowerGrowthRate />
            </>
          )
        }
      ]}
    />
  );
};
