import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { Template } from './Template';
import { useUsersFilter } from './hooks';

export const UsersFilter = () => {
  const { filter, setFilterForm } = useUsersFilter();

  return (
    <FilterWrapper>
      <FilterProvider initialValues={filter} onSubmit={setFilterForm} basicFilter={<Template />} />
    </FilterWrapper>
  );
};

export { useUsersFilter };
export * from './schemaTypes';
