import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { AffiliateReportShowBy, MarketplaceAffiliateReportByInfluencerField } from '@/graphql';

export const MarketplaceAffiliateReportSearchSchema = SearchSchema.create({
  sort: { field: MarketplaceAffiliateReportByInfluencerField },
  filter: {
    schema: z.object({
      endDate: SearchSchema.types.string().optional(),
      startDate: SearchSchema.types.string().optional(),
      mode: SearchSchema.types.enum(AffiliateReportShowBy)
    }),
    defaultValues: {
      endDate: undefined, // NOTE: undefined is needed to set the default period if the filter is not set
      startDate: undefined,
      mode: AffiliateReportShowBy.DATE
    }
  }
});

export type MarketplaceAffiliateReportSearchSchemaType = z.infer<typeof MarketplaceAffiliateReportSearchSchema>;
export type MarketplaceAffiliateReportFilterFormValues = MarketplaceAffiliateReportSearchSchemaType['filter'];
export type MarketplaceAffiliateReportFilterFormKeys = UnionOfKeys<MarketplaceAffiliateReportFilterFormValues>;
