import { useTranslation } from 'react-i18next';

import { useAllAdvertisersForSearchOptions, useAllAvailableCountryOptions } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';

import { UsersFilterFormKeys } from './schemaTypes';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { allAdvertiserOptions, loading } = useAllAdvertisersForSearchOptions();
  const { allAvailableCountryOptions } = useAllAvailableCountryOptions({ hasUSCountry: true });

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px 16px' }}>
      <TextSearchField<UsersFilterFormKeys>
        name="keyword"
        onSubmit={onSubmit}
        title={t('Search.Keyword')}
        placeholder={t<string>('Search.UserFilter')}
      />

      <SelectorField<UsersFilterFormKeys>
        title={t('Selector.Advertisers')}
        disabled={loading}
        onSubmit={onSubmit}
        name="advertiserIds"
        css={{ maxWidth: '314px' }}
        options={allAdvertiserOptions}
        multiple
      />

      <SelectorField<UsersFilterFormKeys>
        title={t('Selector.Country')}
        name="countryId"
        onSubmit={onSubmit}
        css={{ maxWidth: '194px' }}
        options={allAvailableCountryOptions}
      />
    </BasicFilterWrapper>
  );
};
