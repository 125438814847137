import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { LIMIT_50 } from '@/shared/constants';
import { SearchSchema } from '@/shared/validations';
import { Genders, InfluencerListSortField, MarketplaceCampaignAppliedStatus } from '@/graphql';

export const MarketplaceApplicantsSearchSchema = SearchSchema.create({
  limit: LIMIT_50,
  sort: { field: InfluencerListSortField },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      minAge: SearchSchema.types.number(),
      maxAge: SearchSchema.types.number(),
      minFollowers: SearchSchema.types.number(),
      maxFollowers: SearchSchema.types.number(),
      minEngagementRate: SearchSchema.types.number(),
      maxEngagementRate: SearchSchema.types.number(),
      categoryIds: SearchSchema.types.arrayString(),
      genders: SearchSchema.types.arrayEnum(Genders),
      appliedStatus: SearchSchema.types.enum(MarketplaceCampaignAppliedStatus)
    })
  }
});

export type MarketplaceApplicantsSearchSchemaType = z.infer<typeof MarketplaceApplicantsSearchSchema>;
export type MarketplaceApplicantsFilterFormValues = MarketplaceApplicantsSearchSchemaType['filter'];
export type MarketplaceApplicantsFilterFormKeys = UnionOfKeys<MarketplaceApplicantsFilterFormValues>;
