import { useTranslation } from 'react-i18next';
import { TikTokAdReportType, useAllTiktokAdReportSummaryQuery, useAllTiktokAdsReportQuery } from '@/graphql';
import { AdCampaignChart, ListWithPagination } from '@/shared/molecules';
import {
  AdCampaignReportFilter,
  AdCampaignsReportSummary,
  EmptyAdCampaign,
  AdCampaignReportTable,
  TikTokSummaryDataContext,
  useAdCampaignReportFilter
} from '@/shared/organisms';
import { convertOrderToORDER, getOffset, getValuableVariables } from '@/shared/utils';
import { ListEmpty, RenderDataWithFallback } from '@/shared/atoms';

export const TikTokAdCampaignReport = ({ summaryData: sData }: TikTokSummaryDataContext) => {
  const { t } = useTranslation();
  const { page, limit, filter, sort, setFilter } = useAdCampaignReportFilter();

  const baseVariables = {
    ...filter,
    status: filter?.status ? [filter.status] : null,
    limit,
    offset: getOffset(page, limit),
    orderBy: { field: sort.field || null, order: convertOrderToORDER(sort.order) }
  };

  const numberCreatedCampaigns = sData?.numberCreatedCampaigns;
  const adReportType = TikTokAdReportType.AD_CAMPAIGN;

  const {
    data: summaryData,
    previousData: summaryPreviousData,
    loading
  } = useAllTiktokAdReportSummaryQuery({
    variables: getValuableVariables({
      ...baseVariables,
      adReportType
    })
  });
  const {
    data: rData,
    previousData: rPreviousData,
    loading: rLoading
  } = useAllTiktokAdsReportQuery({
    variables: getValuableVariables({
      ...baseVariables,
      adReportType
    })
  });

  if (numberCreatedCampaigns === 0) {
    return <EmptyAdCampaign />;
  }

  const summaryResult = loading ? summaryPreviousData : summaryData;
  const rResult = rLoading ? rPreviousData : rData;
  const summary = summaryResult?.allTiktokAdReportSummary;
  const allReports = rResult?.allTiktokAdsReport;

  return (
    <ListWithPagination loading={loading} pagination={{ totalRecords: allReports?.length }}>
      <AdCampaignReportFilter filter={filter} setFilter={setFilter} />

      <RenderDataWithFallback
        loading={loading}
        hasNoData={!allReports?.length}
        noDataNode={
          <ListEmpty
            title={t('General.No report data has been found')}
            description={t('General.Please try a different query or wait the data is reflected')}
          />
        }
      >
        <AdCampaignsReportSummary data={summary} />
        <AdCampaignChart data={summary?.chartData} />
        <AdCampaignReportTable
          filter={filter}
          loading={rLoading}
          summaryData={summary}
          adReportType={adReportType}
          allTiktokAdsReport={allReports || []}
        />
      </RenderDataWithFallback>
    </ListWithPagination>
  );
};
