import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { TwitterAnalyticsPostsSortOrder } from '@/graphql';

export const InfluencerTwitterPostsSearchSchema = SearchSchema.create({
  sort: { field: TwitterAnalyticsPostsSortOrder },
  filter: {
    schema: z.object({
      name: SearchSchema.types.string(),
      keyword: SearchSchema.types.string()
    })
  }
});

export type InfluencerTwitterPostsSearchSchemaType = z.infer<typeof InfluencerTwitterPostsSearchSchema>;
