import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { TikTokSpecialCampaignStatus } from '@/graphql';

export const TikTokCampaignsSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      status: SearchSchema.types.enum(TikTokSpecialCampaignStatus)
    })
  }
});

export type TikTokCampaignsSearchType = z.infer<typeof TikTokCampaignsSearchSchema>;
export type TikTokCampaignsFilterFormValues = TikTokCampaignsSearchType['filter'];
export type TikTokCampaignsFilterFormKeys = UnionOfKeys<TikTokCampaignsFilterFormValues>;
