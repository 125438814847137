import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export const AnalyticsYoutubeShortsSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsPostsSortOrder },
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      endDate: SearchSchema.types.string(),
      startDate: SearchSchema.types.string(),
      brandAccountName: SearchSchema.types.string()
    })
  }
});

export type AnalyticsYoutubeShortsSearchSchemaType = z.infer<typeof AnalyticsYoutubeShortsSearchSchema>;
