import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { DouyinInfluencer, SocialAccountInfluencerListSortFieldV2, SocialAccountType } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const DouyinInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  includeIds: true,
  ttcmStatus: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  talentAgencyOrPartnerIds: true,
  // audience section is different from other SM
  demographicAge: true,
  demographicGender: true,
  demographicAgeMinRate: true,
  demographicGenderMinRate: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultDouyinInfluencersFormValues = DouyinInfluencersFilterSchema.parse({
  countryIds: ['CN'], // fixed for douyin
  tab: InfluencersTabType.DOUYIN
});

export const DouyinInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: DouyinInfluencersFilterSchema,
    defaultValues: defaultDouyinInfluencersFormValues
  }
});

export type DouyinInfluencersSearchSchemaType = z.infer<typeof DouyinInfluencersSearchSchema>;
export type DouyinInfluencersFilterFormValues = DouyinInfluencersSearchSchemaType['filter'];
export type DouyinInfluencersFilterFormKeys = UnionOfKeys<DouyinInfluencersFilterFormValues>;
export type DouyinInfluencersProps = DefaultInfluencersProps<DouyinInfluencerProfile>;
export type DouyinInfluencerProfile = {
  posts: PostType[];
  socialAccountType: SocialAccountType;
} & Pick<
  DouyinInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
