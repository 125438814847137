import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys, TtcmStatusOption } from '@/shared/types';
import { SocialAccountInfluencerListSortFieldV2, SocialAccountType, TiktokInfluencer } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const TiktokInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  ttcmStatus: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  talentAgencyOrPartnerIds: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true,
  demographicAge: true,
  demographicGender: true,
  demographicAgeMinRate: true,
  demographicGenderMinRate: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultTiktokInfluencersFormValues = TiktokInfluencersFilterSchema.parse({
  tab: InfluencersTabType.TIKTOK,
  ttcmStatus: TtcmStatusOption.ALL
});

export const TiktokInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: TiktokInfluencersFilterSchema,
    defaultValues: defaultTiktokInfluencersFormValues
  }
});

export type TiktokInfluencersSearchSchemaType = z.infer<typeof TiktokInfluencersSearchSchema>;
export type TiktokInfluencersFilterFormValues = TiktokInfluencersSearchSchemaType['filter'];
export type TiktokInfluencersFilterFormKeys = UnionOfKeys<TiktokInfluencersFilterFormValues>;
export type TiktokInfluencersProps = DefaultInfluencersProps<TiktokInfluencerProfile>;
export type TiktokInfluencerProfile = {
  posts: PostType[];
  isTTCMCreator?: boolean;
  socialAccountType: SocialAccountType;
} & Pick<
  TiktokInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'avatar'
  | 'gender'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'isBrandAccount'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
