import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';
import { XhsPostType, PostListSortField } from '@/graphql';

export const InfluencerXhsPostsFilterSchema = z.object({
  keyword: SearchSchema.types.string(),
  postType: SearchSchema.types.arrayEnum(XhsPostType)
});
export const InfluencerXhsPostsSearchSchema = SearchSchema.create({
  sort: { field: PostListSortField },
  filter: { schema: InfluencerXhsPostsFilterSchema }
});

export type InfluencerXhsPostsSearchSchemaType = z.infer<typeof InfluencerXhsPostsSearchSchema>;
export type InfluencerXhsPostsFilterFormValues = InfluencerXhsPostsSearchSchemaType['filter'];
export type InfluencerXhsPostsFilterFormKeys = UnionOfKeys<InfluencerXhsPostsFilterFormValues>;
