import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const MarketplaceJoinedInfluencersSearchSchema = SearchSchema.create({
  filter: { schema: z.object({ keyword: SearchSchema.types.string() }) }
});

export type MarketplaceJoinedInfluencersSearchSchemaTypes = z.infer<typeof MarketplaceJoinedInfluencersSearchSchema>;
export type MarketplaceJoinedInfluencersFilterValues = MarketplaceJoinedInfluencersSearchSchemaTypes['filter'];
export type MarketplaceJoinedInfluencersFilterFormKeys = UnionOfKeys<MarketplaceJoinedInfluencersFilterValues>;
