import { z } from 'zod';
import { getToday } from '@/shared/utils';
import { getThirtyDaysAgo } from '@/shared/utils';
import { SearchSchema } from '@/shared/validations';
import { ProfilePostsFilterSchema } from '@/shared/organisms';
import { YoutubeAnalyticsComparePostsSortOrder, YoutubeAnalyticsPostsSortOrder } from '@/graphql';

export interface CompareAccountType {
  accountId: number;
  avatarUrl: string;
  channelName: string;
}

const AnalyticsYoutubeCompareSearchBase = SearchSchema.create({
  filter: {
    schema: z.object({ accountId: SearchSchema.types.string() }).merge(ProfilePostsFilterSchema),
    defaultValues: { endDate: getToday(), startDate: getThirtyDaysAgo() }
  }
});
export const AnalyticsYoutubeCompareSearchSchema = AnalyticsYoutubeCompareSearchBase.merge(
  z.object({
    sort: SearchSchema.types.sort(YoutubeAnalyticsComparePostsSortOrder).default({})
  })
);
export const AnalyticsYoutubeMainSearchSchema = AnalyticsYoutubeCompareSearchBase.merge(
  z.object({
    sort: SearchSchema.types.sort(YoutubeAnalyticsPostsSortOrder).default({})
  })
);

export type AnalyticsYoutubeMainSearchSchemaType = z.infer<typeof AnalyticsYoutubeMainSearchSchema>;
export type AnalyticsYoutubeCompareSearchSchemaType = z.infer<typeof AnalyticsYoutubeCompareSearchSchema>;
