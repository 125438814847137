import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';

// TODO: recheck why InfluencerAccountSearchSchema need to merge with YtTagRankingFilterSchema
export const YtTagRankingFilterSchema = z.object({
  name: SearchSchema.types.string(),
  keyword: SearchSchema.types.string(),
  endDate: SearchSchema.types.string(),
  startDate: SearchSchema.types.string()
});

export const YtTagRankingSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsTagRankingSortOrder },
  filter: {
    schema: YtTagRankingFilterSchema,
    defaultValues: { endDate: getToday(), startDate: getThirtyDaysAgo() }
  }
});

export type YtTagRankingSearchSchemaType = z.infer<typeof YtTagRankingSearchSchema>;
