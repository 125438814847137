import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const AssociatedAccountSearchSchema = SearchSchema.create({
  filter: { schema: z.object({ keyword: SearchSchema.types.string() }) }
});

export type AssociatedAccountSearchSchemaType = z.infer<typeof AssociatedAccountSearchSchema>;
export type AssociatedAccountFilterFormValues = AssociatedAccountSearchSchemaType['filter'];
export type AssociatedAccountFilterFormKeys = UnionOfKeys<AssociatedAccountFilterFormValues>;
