import { z } from 'zod';
import { CheckBoxProps } from '@/shared/atoms';
import { SearchSchema } from '@/shared/validations';
import {
  SortType,
  SortAction,
  UnionOfKeys,
  LimitAction,
  FilterAction,
  ToggleOption,
  TtcmStatusOption,
  InfluencersTabType
} from '@/shared/types';
import {
  Genders,
  CampaignType,
  SocialPostType,
  SocialAccountType,
  SocialAccountStatus,
  QuerySocialAccountType,
  InfluencerStatusForSearch,
  FollowersGrowthPeriodEnum,
  InfluencerSearchKeywordField,
  QueryallInstagramSimilarInfluencersV3Args
} from '@/graphql';
import { WidgetSelectButtonProps } from './shared';

export const InfluencersFilterBaseSchema = z.object({
  tab: SearchSchema.types.enum(InfluencersTabType),
  keyword: SearchSchema.types.string(),
  countryId: SearchSchema.types.string(),
  audienceAge: SearchSchema.types.string(),
  minPostDate: SearchSchema.types.string(),
  maxPostDate: SearchSchema.types.string(),
  audienceRatio: SearchSchema.types.string(),
  regionIds: SearchSchema.types.arrayString(),
  interests: SearchSchema.types.arrayString(),
  includeIds: SearchSchema.types.arrayString(),
  countryIds: SearchSchema.types.arrayString(),
  categoryIds: SearchSchema.types.arrayString(),
  campaignCategoryIds: SearchSchema.types.arrayString(),
  talentAgencyOrPartnerIds: SearchSchema.types.arrayString(),
  minAge: SearchSchema.types.number(),
  maxAge: SearchSchema.types.number(),
  minViews: SearchSchema.types.number(),
  maxViews: SearchSchema.types.number(),
  minReach: SearchSchema.types.number(),
  maxReach: SearchSchema.types.number(),
  minSaveRate: SearchSchema.types.number(),
  maxSaveRate: SearchSchema.types.number(),
  maxPostPrice: SearchSchema.types.number(),
  minPostPrice: SearchSchema.types.number(),
  minFollowers: SearchSchema.types.number(),
  maxFollowers: SearchSchema.types.number(),
  minStoryPrice: SearchSchema.types.number(),
  maxStoryPrice: SearchSchema.types.number(),
  minVideoPrice: SearchSchema.types.number(),
  maxVideoPrice: SearchSchema.types.number(),
  minPicturePrice: SearchSchema.types.number(),
  maxPicturePrice: SearchSchema.types.number(),
  minEngagementRate: SearchSchema.types.number(),
  maxEngagementRate: SearchSchema.types.number(),
  minCampaignsJoined: SearchSchema.types.number(),
  maxCampaignsJoined: SearchSchema.types.number(),
  minEngagementViewsRate: SearchSchema.types.number(),
  maxEngagementViewsRate: SearchSchema.types.number(),
  minEngagementFollowersRate: SearchSchema.types.number(),
  maxEngagementFollowersRate: SearchSchema.types.number(),
  followerGrowthPercentage: SearchSchema.types.number(),
  audienceGender: SearchSchema.types.enum(Genders),
  status: SearchSchema.types.enum(SocialAccountStatus),
  ttcmStatus: SearchSchema.types.enum(TtcmStatusOption),
  campaignDisplay: SearchSchema.types.enum(ToggleOption),
  keywordField: SearchSchema.types.enum(InfluencerSearchKeywordField),
  followerGrowthPeriod: SearchSchema.types.enum(FollowersGrowthPeriodEnum),
  socialMedia: SearchSchema.types.enum(QuerySocialAccountType).or(SearchSchema.types.enum(SocialPostType)),
  genders: SearchSchema.types.arrayEnum(Genders),
  statuses: SearchSchema.types.arrayEnum(InfluencerStatusForSearch),
  socialMedias: SearchSchema.types.arrayEnum(QuerySocialAccountType),
  demographicAge: SearchSchema.types.string(), // for API we will split to {start: Int!, end: Int!}
  demographicGender: SearchSchema.types.enum(Genders),
  demographicAgeMinRate: SearchSchema.types.string(),
  demographicGenderMinRate: SearchSchema.types.string()
});
export const InfluencersBaseSearchStateSchema: Parameters<
  typeof SearchSchema.create<any, any, z.ZodObject<{ skipSearch: z.ZodCatch<z.ZodDefault<z.ZodBoolean>> }>>
>[0]['state'] = { schema: z.object({ skipSearch: SearchSchema.types.boolean() }) };
export const InfluencersBaseSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  filter: { schema: InfluencersFilterBaseSchema.partial() }
});

export type InfluencersBaseSearchSchemaType = z.infer<typeof InfluencersBaseSearchSchema>;
export type InfluencersFilterFormValues = z.infer<typeof InfluencersFilterBaseSchema>;
export type InfluencersFilterFormKeys = UnionOfKeys<InfluencersFilterFormValues>;

export interface BasicFilterDefaultProps {
  onSubmit?: () => void;
  isEngagementPage?: boolean;
  isTtcmCampaign?: boolean;
  isPackagePage?: boolean;
}

export interface AdvancedFilterDefaultProps {
  count: number;
  onSubmit?: () => void;
  isTtcmCampaign?: boolean;
  isEngagementPage?: boolean;
}

export interface DefaultInfluencersProps<Profile = never> {
  isPackagePage?: boolean;
  isTtcmCampaign?: boolean;
  isEngagementPage?: boolean;
  getListSelectButtonProps?: (influencer: Profile) => WidgetSelectButtonProps;
  getSortSelectButtonProps?: (listRecords: readonly Profile[], totalRecords: number) => SortSelectButtonProps;
}

export interface SelectedSocialMediaAccountsType {
  influencerId: number;
  socialAccountId?: number | string | null;
  socialAccountType: SocialAccountType | null;
}

export interface SortSelectButtonProps extends CheckBoxProps {
  hasAllSelected?: boolean;
  isInfluencerAccount?: boolean;
  selectedAccountsInPage?: number[];
  selectedSocialMediaAccounts?: SelectedSocialMediaAccountsType[];
}

export interface InfluencersContextReturnType<
  Profile,
  F extends Partial<InfluencersFilterFormValues>,
  S extends string = string
> extends LimitAction,
    SortAction<S>,
    FilterAction<F> {
  page: number;
  loading: boolean;
  totalRecords: number;
  listRecords: readonly Profile[];
  isPackagePage?: boolean;
  isTtcmCampaign?: boolean;
  isEngagementPage?: boolean;
  sortSelectButtonProps?: SortSelectButtonProps;
  getListSelectButtonProps?: (influencer: Profile) => WidgetSelectButtonProps;
  setSortAndLimit: ({ sort, limit }: { sort: SortType<S>; limit: number }) => void;
}

export interface InfluencersSortFormValues<T extends string = string> {
  sort: SortType<T>;
  limit: string;
}

export type InfluencersSortFormKeys = keyof InfluencersSortFormValues<string>;

export interface PostType {
  caption?: string;
  id?: string | null;
  type?: SocialPostType;
  thumbNail?: string | null;
  influencerId: number | null;
  campaignId?: number | null;
  campaignType?: CampaignType | null;
}

export type RefetchSimilarInfluencersVariables = Pick<
  QueryallInstagramSimilarInfluencersV3Args,
  'limit' | 'minAge' | 'maxAge' | 'genders' | 'minFollowers' | 'maxFollowers'
>;

export interface DefaultProfileWidgetProps<Profile> {
  profile: Profile;
  className?: string;
  isSectionOpened?: boolean;
  hasSimilarInfluencers?: boolean;
  selectButtonProps?: WidgetSelectButtonProps;
  toggleSimilarInfluencersSection?: () => void;
}
