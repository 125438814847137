import { useTranslation } from 'react-i18next';
import { useParamsSearch } from '@/shared/hooks';
import { ListWithPagination } from '@/shared/molecules';
import { getOffset, getValuableVariables } from '@/shared/utils';
import { useInfluencerYoutubeAnalyticsShortsQuery } from '@/graphql';
import { ProfilePostsCount, ProfilePostsFilter, InfluencerYoutubeShortsList } from '@/shared/organisms';
import { useInitialInfluencerPostsListWithName } from '../hooks';
import { InfluencerYoutubeShortsSearchSchemaType } from './schemaTypes';

export const InfluencerYoutubeShorts = () => {
  const { t } = useTranslation();
  const { influencerId, mainSocialAccountId } = useInitialInfluencerPostsListWithName({
    title: 'Shorts List',
    titleWithName: 'appHeader.ShortsWithAccountName'
  });
  const { page, limit, sort, filter, setFilter } = useParamsSearch<InfluencerYoutubeShortsSearchSchemaType>();
  const { data, previousData, loading } = useInfluencerYoutubeAnalyticsShortsQuery({
    skip: !influencerId || !mainSocialAccountId,
    variables: getValuableVariables({
      limit,
      socialAccountId: mainSocialAccountId,
      orderBy: sort,
      offset: getOffset(page, limit),
      ...filter
    })
  });

  const result = loading ? previousData : data;
  const totalRecords = result?.influencerYoutubeAnalyticsShortsCount?.totalNumber;

  return (
    <ListWithPagination pagination={{ totalRecords }}>
      <ProfilePostsFilter filter={filter} setFilter={setFilter} hasPeriod />

      <ProfilePostsCount count={totalRecords} countLabel={t('Shorts found')} />

      <InfluencerYoutubeShortsList
        loading={loading}
        mainSocialAccountId={mainSocialAccountId}
        data={result?.influencerYoutubeAnalyticsShorts?.shorts || []}
      />
    </ListWithPagination>
  );
};
