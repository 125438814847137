import { useTranslation } from 'react-i18next';
import { useAllInfluencerCategoryOptions } from '@/shared/hooks';
import { SelectorFieldProps, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const InfluencerCategoryField = (props: Omit<SelectorFieldProps, 'name'>) => {
  const { t } = useTranslation();
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();

  return (
    <SelectorField<InfluencersFilterFormKeys>
      name="categoryIds"
      title={t('Selector.Categories')}
      options={allInfluencerCategoryOptions}
      multiple
      {...props}
    />
  );
};
