import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const UsersSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      advertiserIds: SearchSchema.types.arrayString()
    })
  }
});

export type UsersSearchSchemaType = z.infer<typeof UsersSearchSchema>;
export type UsersFilterFormValues = UsersSearchSchemaType['filter'];
export type UsersFilterFormKeys = UnionOfKeys<UsersFilterFormValues>;
