import { FilterAction } from '@/shared/types';
import { FilterWrapper, FilterProvider } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { InfluencerXhsPostsFilterFormValues } from './schemaTypes';

export interface InfluencerXhsPostsFilterProps
  extends FilterAction<InfluencerXhsPostsFilterFormValues>,
    Omit<TemplateProps, 'onSubmit'> {
  className?: string;
}

export const InfluencerXhsPostsFilter = ({
  filter,
  setFilter,
  className,
  ...templateProps
}: InfluencerXhsPostsFilterProps) => (
  <FilterWrapper className={className} css={{ boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);' }}>
    <FilterProvider onSubmit={setFilter} initialValues={filter} basicFilter={<Template {...templateProps} />} />
  </FilterWrapper>
);

export * from './schemaTypes';
