import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { ProfilePostsFilterSchema } from './schemaTypes';

export const defaultProfilePostsFilterSchema: Parameters<
  typeof SearchSchema.create<z.ZodObject<typeof ProfilePostsFilterSchema.shape>, any, any>
>[0]['filter'] = {
  schema: ProfilePostsFilterSchema,
  defaultValues: { startDate: getThirtyDaysAgo(), endDate: getToday() }
};
