import { useFormContext } from 'react-hook-form';
import { FILTER_SECTIONS, MoreFilterPopover } from '@/shared/molecules';
import { ViewField, FollowerField, PostDateField, EngagementRateField, InfluencerCategoryField } from '../../shared';
import { AdvancedFilterDefaultProps } from '../../schemaTypes';
import { InfluencerPostsFilterFormValues } from '../schemaTypes';
import { getInitialInfluencerPostsFormValues } from '../utils';

export const AdvancedFilter = ({ count, onSubmit }: AdvancedFilterDefaultProps) => {
  const { reset, getValues } = useFormContext<InfluencerPostsFilterFormValues>();

  const values = getValues();
  const emptyValues = getInitialInfluencerPostsFormValues();

  const handleClear = () => {
    reset({
      ...emptyValues,
      // Basic filter
      keyword: values.keyword,
      genders: values.genders,
      countryId: values.countryId,
      socialMedia: values.socialMedia
    });
  };

  return (
    <MoreFilterPopover
      count={count}
      onClear={handleClear}
      onSubmit={onSubmit}
      items={[
        {
          value: FILTER_SECTIONS.InfluencerAttributes,
          title: FILTER_SECTIONS.InfluencerAttributes,
          node: <InfluencerCategoryField />
        },
        {
          value: FILTER_SECTIONS.PerformanceFilter,
          title: FILTER_SECTIONS.PerformanceFilter,
          node: (
            <>
              <FollowerField />
              <EngagementRateField />
              <ViewField />
              <PostDateField withinPortal={false} />
            </>
          )
        }
      ]}
    />
  );
};
