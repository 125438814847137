import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { LIMIT_36 } from '@/shared/constants';
import { SearchSchema } from '@/shared/validations';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { Genders, SocialPostType, PostListSortFieldV3 } from '@/graphql';

// This UI mixed Filter & Sort together
export const PostsFilterSearchSchema = SearchSchema.create({
  limit: LIMIT_36,
  filter: {
    schema: z
      .object({
        sort: SearchSchema.types.string(), // This is selected sort option, convert selected option to a string value for selector component
        keyword: SearchSchema.types.string(),
        countryId: SearchSchema.types.string(),
        minPostDate: SearchSchema.types.string(),
        maxPostDate: SearchSchema.types.string(),
        categoryIds: SearchSchema.types.arrayString(),
        genders: SearchSchema.types.arrayEnum(Genders),
        socialMedia: SearchSchema.types.enum(SocialPostType),
        minViews: SearchSchema.types.number(),
        maxViews: SearchSchema.types.number(),
        minFollowers: SearchSchema.types.number(),
        maxFollowers: SearchSchema.types.number(),
        minEngagementRate: SearchSchema.types.number(),
        maxEngagementRate: SearchSchema.types.number()
      })
      .merge(SearchSchema.types.sort(PostListSortFieldV3)),
    defaultValues: {
      maxPostDate: getToday(),
      minPostDate: getThirtyDaysAgo(),
      socialMedia: SocialPostType.INSTAGRAM
    }
  }
});

export type PostsFilterSearchSchemaType = z.infer<typeof PostsFilterSearchSchema>;
export type PostsFilterFormValues = PostsFilterSearchSchemaType['filter'];
export type PostsFilterFormKeys = UnionOfKeys<PostsFilterFormValues>;
