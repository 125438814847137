import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InstagramFeedPostSortField } from '@/graphql';
import { defaultProfilePostsFilterSchema } from '@/shared/organisms';

export const AnalyticsIgMentionedPostsSearchSchema = SearchSchema.create({
  filter: defaultProfilePostsFilterSchema,
  sort: { field: InstagramFeedPostSortField }
});

export type AnalyticsIgMentionedPostsSearchSchemaType = z.infer<typeof AnalyticsIgMentionedPostsSearchSchema>;
