import { useTranslation } from 'react-i18next';
import { Option } from '@/shared/types';
import { SocialIcon } from '@/shared/atoms';
import { BasicFilterWrapper, SelectorField, SelectorFieldV2 } from '@/shared/molecules';
import { useAllCountryOptions, useFollowerGrowthOptions, useAllInfluencerCategoryOptions } from '@/shared/hooks';
import { TrendingInfluencersTabType, TrendingInfluencersFilterFormKeys } from './schemaTypes';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { allCountryOptions } = useAllCountryOptions();
  const { followerGrowthPeriodOptions } = useFollowerGrowthOptions();
  const { allInfluencerCategoryOptions } = useAllInfluencerCategoryOptions();

  const followerOptions: Option[] = [
    { label: '0~', value: '0' },
    { label: '10,000~', value: '10000' },
    { label: '100,000~', value: '100000' },
    { label: '500,000~', value: '500000' }
  ];
  const socialMediaOptions: Option[] = [
    {
      label: t<string>('Instagram'),
      prefixIcon: <SocialIcon type="INSTAGRAM" />,
      value: TrendingInfluencersTabType.INSTAGRAM
    },
    {
      label: t<string>('Youtube'),
      value: TrendingInfluencersTabType.YOUTUBE,
      prefixIcon: <SocialIcon type="YOUTUBE" />
    },
    {
      label: t<string>('Facebook'),
      value: TrendingInfluencersTabType.FACEBOOK,
      prefixIcon: <SocialIcon type="FACEBOOK" />
    }
  ];

  return (
    <BasicFilterWrapper>
      <SelectorFieldV2<TrendingInfluencersFilterFormKeys>
        name="tab"
        menuWidth="auto"
        menuHeight="auto"
        clearable={false}
        title={t('Selector.Social Media')}
        onSubmit={onSubmit}
        css={{ maxWidth: '160px' }}
        options={socialMediaOptions}
        placeholder={t<string>('Selector.Social Media')}
      />

      <SelectorField<TrendingInfluencersFilterFormKeys>
        title={t('Selector.Country')}
        name="countryId"
        onSubmit={onSubmit}
        options={allCountryOptions}
        css={{ maxWidth: '196px' }}
        placeholder={t<string>('Selector.Country')}
        singleSelectorProps={{ hideDeselectOption: true }}
      />

      <SelectorField<TrendingInfluencersFilterFormKeys>
        title={t('Selector.Topics')}
        name="categoryIds"
        onSubmit={onSubmit}
        css={{ maxWidth: '196px' }}
        options={allInfluencerCategoryOptions}
        placeholder={t<string>('Selector.topicsPlaceholder')}
        multiple
      />

      <SelectorField<TrendingInfluencersFilterFormKeys>
        onSubmit={onSubmit}
        title={t('Selector.Growth period')}
        css={{ maxWidth: '196px' }}
        name="followerGrowthPeriod"
        options={followerGrowthPeriodOptions}
        singleSelectorProps={{ hideDeselectOption: true }}
        placeholder={t<string>('Selector.Follower Growth Period')}
      />

      <SelectorField<TrendingInfluencersFilterFormKeys>
        title={t('Selector.Followers')}
        onSubmit={onSubmit}
        name="minFollowers"
        options={followerOptions}
        css={{ maxWidth: '196px' }}
        singleSelectorProps={{ hideDeselectOption: true }}
        placeholder={t<string>('Selector.Number of Followers')}
      />
    </BasicFilterWrapper>
  );
};
