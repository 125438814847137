import { InfluencersFilterWrapper, stickyInfluencersSearch } from '../shared';
import { InstagramInfluencersProvider } from './InstagramInfluencersContext';
import { InstagramInfluencersFilter } from './InstagramInfluencersFilter';
import { InstagramInfluencersList } from './InstagramInfluencersList';
import { InstagramInfluencersSort } from './InstagramInfluencersSort';
import { InstagramInfluencersProps } from './schemaTypes';

export const InstagramInfluencers = (props: InstagramInfluencersProps) => (
  <InstagramInfluencersProvider value={props}>
    <InfluencersFilterWrapper css={stickyInfluencersSearch}>
      <InstagramInfluencersFilter />
      <InstagramInfluencersSort />
    </InfluencersFilterWrapper>

    <InstagramInfluencersList />
  </InstagramInfluencersProvider>
);

export * from './utils'; // TODO DEMO: remove later
export * from './schemaTypes';
export { useInstagramSimilarInfluencersList } from './hooks';
