import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InstagramStoryPostSortField } from '@/graphql';

export const InfluencerInstagramStoryPostsSearchSchema = SearchSchema.create({
  sort: { field: InstagramStoryPostSortField },
  filter: {
    schema: z.object({
      name: SearchSchema.types.string(),
      keyword: SearchSchema.types.string()
    })
  }
});

export type InfluencerInstagramStoryPostsSearchType = z.infer<typeof InfluencerInstagramStoryPostsSearchSchema>;
