import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { SocialAccountType, XhsInfluencer, SocialAccountInfluencerListSortFieldV2 } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const XhsInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  countryIds: true,
  includeIds: true,
  categoryIds: true,
  keywordField: true,
  minVideoPrice: true,
  maxVideoPrice: true,
  minFollowers: true,
  maxFollowers: true,
  demographicAge: true,
  minPicturePrice: true,
  maxPicturePrice: true,
  demographicGender: true,
  demographicAgeMinRate: true,
  demographicGenderMinRate: true,
  talentAgencyOrPartnerIds: true,
  minEngagementViewsRate: true,
  maxEngagementViewsRate: true,
  minEngagementFollowersRate: true,
  maxEngagementFollowersRate: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultXhsInfluencersFormValues = XhsInfluencersFilterSchema.parse({
  tab: InfluencersTabType.XHS
});

export const XhsInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: XhsInfluencersFilterSchema,
    defaultValues: defaultXhsInfluencersFormValues
  }
});

export type XhsInfluencersSearchSchemaType = z.infer<typeof XhsInfluencersSearchSchema>;
export type XhsInfluencersFilterFormValues = XhsInfluencersSearchSchemaType['filter'];
export type XhsInfluencersFilterFormKeys = UnionOfKeys<XhsInfluencersFilterFormValues>;
export type XhsInfluencersProps = DefaultInfluencersProps<XhsInfluencerProfile>;
export type XhsInfluencerProfile = { posts: PostType[]; socialAccountType: SocialAccountType } & Pick<
  XhsInfluencer,
  | 'id'
  | 'age'
  | 'name'
  | 'gender'
  | 'avatar'
  | 'country'
  | 'averageLikes'
  | 'averageViews'
  | 'socialAccountId'
  | 'socialAccountStatus'
  | 'socialAccountFollowers'
  | 'socialAccountEngagementViewsRate'
  | 'socialAccountEngagementFollowersRate'
>;
