import { useTranslation } from 'react-i18next';
import { THEME, YT_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY } from '@/shared/constants';
import { SimilarInfluencersTemplate, WidgetSelectButtonProps } from '../../shared';
import { RefetchSimilarInfluencersVariables } from '../../schemaTypes';
import { useYoutubeSimilarInfluencersList } from '../hooks';
import { YoutubeInfluencerProfile } from '../schemaTypes';
import { ProfileWidget } from './ProfileWidget';

interface SimilarInfluencersListProps {
  socialAccountId: string;
  isSectionOpened: boolean;
  getListSelectButtonProps?: (influencer: YoutubeInfluencerProfile) => WidgetSelectButtonProps;
}

export const SimilarInfluencersList = ({
  socialAccountId,
  isSectionOpened,
  getListSelectButtonProps
}: SimilarInfluencersListProps) => {
  const { t } = useTranslation();
  const { limit, error, loading, totalRecords, listRecords, onFetchData, onRefetchData } =
    useYoutubeSimilarInfluencersList({
      socialAccountId
    });

  const handleRefetchData = async (variables: RefetchSimilarInfluencersVariables) => {
    await onRefetchData?.(variables);
  };

  return (
    <SimilarInfluencersTemplate
      isSectionOpened={isSectionOpened}
      loading={loading}
      fetchLimit={limit}
      onFetchData={onFetchData}
      totalRecords={totalRecords}
      onRefetchData={handleRefetchData}
      errorMessage={
        error?.message === YT_ACCOUNT_IS_CREATED_LESS_THAN_1_DAY
          ? t<string>('Similar accounts will be shown in 1-2 days after being added to AnyTag')
          : ''
      }
    >
      {({ currentLimit }) =>
        listRecords
          .slice(0, currentLimit)
          .map((profile, index) => (
            <ProfileWidget
              profile={profile}
              key={`similar-influencer-${profile.id}_${index}`}
              selectButtonProps={getListSelectButtonProps?.(profile)}
              css={{ boxShadow: 'none', backgroundColor: THEME.background.colors.gray.lv1 }}
            />
          ))
      }
    </SimilarInfluencersTemplate>
  );
};
