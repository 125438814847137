import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { getYesterday } from '@/shared/utils';
import { SearchSchema } from '@/shared/validations';

export enum TrendingPostsTabType {
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE'
}

export const TrendingPostsFilterSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      date: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      categoryId: SearchSchema.types.string(),
      tab: SearchSchema.types.enum(TrendingPostsTabType)
    }),
    defaultValues: {
      countryId: 'JP',
      categoryId: '24',
      date: getYesterday(),
      tab: TrendingPostsTabType.YOUTUBE
    }
  }
});

export type TrendingPostsFilterSearchSchemaType = z.infer<typeof TrendingPostsFilterSearchSchema>;
export type TrendingPostsFilterFormValues = TrendingPostsFilterSearchSchemaType['filter'];
export type TrendingPostsFilterFormKeys = UnionOfKeys<TrendingPostsFilterFormValues>;
