import { Table } from '@/shared/atoms';
import { useTableMaxHeight } from '@/shared/hooks';
import { TableData } from '@/shared/types';

import { TrendingInfluencersTabType } from '../TrendingInfluencersFilter/schemaTypes';

import { useTrendingInfluencersTable } from './hooks';
import { TrendingInfluencerProfileType } from './types';

export interface TrendingInfluencersListProps extends TableData<TrendingInfluencerProfileType> {
  tab: TrendingInfluencersTabType;
}

export const TrendingInfluencersList = ({ tab, loading, data = [] }: TrendingInfluencersListProps) => {
  const { rows, columns } = useTrendingInfluencersTable({ tab, data });
  const { offsetRef, defaultTableMaxHeight } = useTableMaxHeight();

  return (
    <Table
      loading={loading}
      data={{ rows, columns }}
      noTopBorderRadius
      stickyHeader
      hasBorder
      ref={offsetRef}
      maxHeight={defaultTableMaxHeight}
    />
  );
};

export { type TrendingInfluencerProfileType } from './types';
