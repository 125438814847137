import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { SearchSchema } from '@/shared/validations';

export const PackagesSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      countryIds: SearchSchema.types.arrayString()
    })
  }
});

export type PackagesSearchSchemaType = z.infer<typeof PackagesSearchSchema>;
export type PackagesFilterFormValues = PackagesSearchSchemaType['filter'];
export type PackagesFilterFormKeys = UnionOfKeys<PackagesFilterFormValues>;
