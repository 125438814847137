import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { getThirtyDaysAgo, getToday } from '@/shared/utils';
import { YoutubeAnalyticsTagRankingSortOrder } from '@/graphql';

export const AnalyticsProfileFilterSchema = z.object({
  endDate: SearchSchema.types.string(),
  startDate: SearchSchema.types.string(),
  brandAccountName: SearchSchema.types.string()
});

export const AnalyticsProfileSearchSchema = SearchSchema.create({
  sort: { field: YoutubeAnalyticsTagRankingSortOrder },
  filter: {
    schema: AnalyticsProfileFilterSchema,
    defaultValues: { endDate: getToday(), startDate: getThirtyDaysAgo() }
  }
});

export type AnalyticsProfileSearchSchemaType = z.infer<typeof AnalyticsProfileSearchSchema>;
