import { useTranslation } from 'react-i18next';
import { BlackBar, ButtonLink } from '@/shared/atoms';
import { SortSelectButtonProps } from '../../schemaTypes';

interface InfluencerSortActionBarTemplateProps {
  selectButtonProps?: SortSelectButtonProps;
}

export const InfluencerSortActionBarTemplate = ({ selectButtonProps }: InfluencerSortActionBarTemplateProps) => {
  const { t } = useTranslation();

  return (
    <BlackBar
      disabled={selectButtonProps?.disabled}
      css={{ padding: '0 12px', borderBottomLeftRadius: 'inherit', borderBottomRightRadius: 'inherit' }}
      checkboxProps={{
        label: selectButtonProps?.label,
        onClick: selectButtonProps?.onClick,
        indeterminate: selectButtonProps?.indeterminate
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <ButtonLink to="/influencers/proposal" title={t('Button.Download & Package')} variant="white" />
      </div>
    </BlackBar>
  );
};
