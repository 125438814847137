import { useTranslation } from 'react-i18next';
import { FormStyle, Label } from '@/shared/atoms';
import { FOLLOWER_GROWTH_PERIOD_OPTIONS, FOLLOWER_GROWTH_RATE_RATIO_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { StyledAdvancedFilter, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

interface FollowerGrowthRateProps {
  title?: string;
  className?: string;
}

export const FollowerGrowthRate = ({ title, className }: FollowerGrowthRateProps) => {
  const { t } = useTranslation();
  const followerGrowthPeriodOptions = useTranslateOptions(FOLLOWER_GROWTH_PERIOD_OPTIONS);

  return (
    <StyledAdvancedFilter.FieldWrapper className={className}>
      <Label title={title ?? t('Follower Growth Rate')} css={{ padding: 0, marginBottom: '11px' }} />
      <FormStyle.FieldsGroup css={{ margin: '-4px -8px' }} itemsPerRow={2}>
        <SelectorField<InfluencersFilterFormKeys>
          title={t('Selector.Period')}
          css={{ padding: '4px' }}
          name="followerGrowthPeriod"
          options={followerGrowthPeriodOptions}
        />

        <SelectorField<InfluencersFilterFormKeys>
          title={t('Selector.Ratio')}
          css={{ padding: '4px' }}
          name="followerGrowthPercentage"
          options={FOLLOWER_GROWTH_RATE_RATIO_OPTIONS}
        />
      </FormStyle.FieldsGroup>
    </StyledAdvancedFilter.FieldWrapper>
  );
};
