import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { LIMIT_36 } from '@/shared/constants';
import { SearchSchema } from '@/shared/validations';
import { UGCPostType, UGCPostStatus, InstagramUGCPostsSortOrder, Order } from '@/graphql';
import { defaultUGCPostsHashtags } from '../../shared';

// This UI mixed Filter & Sort together
export const AnalyticsInstagramUGCPostsFilterSchema = z
  .object({
    keyword: SearchSchema.types.string(),
    hashtagId: SearchSchema.types.string(),
    endDate: SearchSchema.types.string(),
    startDate: SearchSchema.types.string(),
    postType: SearchSchema.types.enum(UGCPostType),
    status: SearchSchema.types.enum(UGCPostStatus)
  })
  .merge(SearchSchema.types.sort(InstagramUGCPostsSortOrder));

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultInstagramUGCPostsFilterFormValues = AnalyticsInstagramUGCPostsFilterSchema.parse({
  order: Order.DESC,
  field: InstagramUGCPostsSortOrder.POST_DATE,
  hashtagId: defaultUGCPostsHashtags.mentioned.id,
  postType: defaultUGCPostsHashtags.mentioned.postType
});

export const AnalyticsInstagramUGCPostsSearchSchema = SearchSchema.create({
  limit: LIMIT_36,
  filter: {
    schema: AnalyticsInstagramUGCPostsFilterSchema,
    defaultValues: defaultInstagramUGCPostsFilterFormValues
  }
});

export type AnalyticsInstagramUGCPostsSearchSchemaType = z.infer<typeof AnalyticsInstagramUGCPostsSearchSchema>;
export type AnalyticsInstagramUGCPostsFilterFormValues = AnalyticsInstagramUGCPostsSearchSchemaType['filter'];
export type AnalyticsInstagramUGCPostsFilterFormKeys = UnionOfKeys<AnalyticsInstagramUGCPostsFilterFormValues>;
