import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { getOffset } from '@/shared/utils';
import { FormAction } from '@/shared/types';
import { THEME } from '@/shared/constants';
import { Header, SelectorField } from '@/shared/molecules';
import { FormProvider, RenderDataWithFallback, SortableList } from '@/shared/atoms';
import {
  useDebounce,
  useQueryHelper,
  useCustomHeader,
  useEventListener,
  useAllCountryOptions,
  useUpdateDocumentTitle,
  useParamsSearch
} from '@/shared/hooks';
import {
  MarketplaceForReorder,
  useAllMarketplacesForReorderLazyQuery,
  useReorderMarketplaceCampaignsMutation
} from '@/graphql';
import { CampaignOrderCard } from './CampaignOrderCard';
import {
  MarketplacesReorderFormKeys,
  MarketplacesReorderFormValues,
  MarketplacesReorderSearchSchemaType
} from './schemaTypes';

export const MarketplacesReorder = () => {
  useCustomHeader();
  useUpdateDocumentTitle({
    href: '/marketplace',
    alwaysBackToParent: true,
    title: 'documentTitle.MarketplaceReorder',
    appHeader: 'appHeader.MarketplaceReorder'
  });
  const { debounce } = useDebounce();
  const [step, setStep] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { t, enqueueSnackbar } = useQueryHelper();
  const { allCountryOptions } = useAllCountryOptions();
  const { limit, filter, setFilter } = useParamsSearch<MarketplacesReorderSearchSchemaType>();
  const [campaigns, setCampaigns] = useState<MarketplaceForReorder[]>([]);
  const { callReorderMarketplaceCampaigns } = useReorderMarketplaceCampaignsMutation();
  const { getAllMarketplacesForReorder, loading, called } = useAllMarketplacesForReorderLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allMarketplacesForReorder }) => {
      if ((allMarketplacesForReorder?.length || 0) < limit) {
        setHasMore(false);
      }

      setCampaigns((prevCampaigns) => [...prevCampaigns, ...(allMarketplacesForReorder || [])]);
    }
  });

  const length = campaigns.length;

  const fetchCampaigns = async (nextPage: number) => {
    if (!filter.countryId || !hasMore) {
      return;
    }

    await getAllMarketplacesForReorder({
      variables: { limit, offset: getOffset(nextPage, limit), countryId: filter.countryId }
    });
  };

  useEffect(() => {
    fetchCampaigns(step);
  }, [filter.countryId]);

  useEventListener('scroll', () => {
    if (
      !loading &&
      hasMore &&
      document.scrollingElement?.scrollHeight &&
      window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement?.scrollHeight
    ) {
      const nextStep = step + 1;
      setStep(nextStep);
      fetchCampaigns(nextStep);
    }
  });

  const handleCountryChange: FormAction<MarketplacesReorderFormValues>['onSubmit'] = ({ countryId }) => {
    // Reset states
    if (countryId !== filter.countryId) {
      setStep(1);
      setCampaigns([]);
      setHasMore(true);
    }

    setFilter({ countryId });
  };

  const handleSubmitReorderCampaigns = async (reorderCampaigns: MarketplaceForReorder[]) => {
    try {
      await callReorderMarketplaceCampaigns({
        variables: { campaignIds: reorderCampaigns.map((campaign) => campaign.id) }
      });
      enqueueSnackbar(t('succeededInSave'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleReorderCampaigns = (reorderCampaigns: MarketplaceForReorder[]) => {
    setCampaigns(reorderCampaigns);
    debounce(() => handleSubmitReorderCampaigns(reorderCampaigns), 3000);
  };

  return (
    <div css={{ width: THEME.container.details.width, margin: '0 auto' }}>
      <Header />

      <div css={styles.topSection}>
        <p css={{ fontSize: '18px', fontWeight: 600 }}>{t('Reorder campaigns')}</p>
        <p css={{ fontSize: '14px', marginTop: '16px' }}>
          {t('You can change the order of the campaigns displayed on the AnyCreator app here')}
        </p>

        <div css={{ marginTop: '24px', maxWidth: '456px' }}>
          <FormProvider<MarketplacesReorderFormValues>
            onSubmit={handleCountryChange}
            defaultValues={{ countryId: filter.countryId }}
          >
            {({ onSubmit }) => (
              <SelectorField<MarketplacesReorderFormKeys>
                name="countryId"
                onSubmit={onSubmit}
                options={allCountryOptions}
                title={t('Selector.Select country')}
              />
            )}
          </FormProvider>
        </div>
      </div>

      {filter.countryId ? (
        <>
          <div css={styles.listSection}>
            <RenderDataWithFallback loading={!called && loading} hasNoData={!length}>
              <SortableList
                items={campaigns}
                onChange={handleReorderCampaigns}
                renderItem={(campaign, index) => (
                  <CampaignOrderCard
                    index={index}
                    data={campaign}
                    key={campaign.id}
                    css={index !== length - 1 && { borderBottom: '1px solid #DEE5EC' }}
                  />
                )}
              />
            </RenderDataWithFallback>
          </div>

          {hasMore && loading && called ? (
            <div css={{ color: THEME.text.colors.gray.lv3, fontSize: '13px', marginTop: '24px', textAlign: 'center' }}>
              {t('Loading more campaigns')}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
const styles = {
  topSection: css({
    padding: '24px',
    borderRadius: '3px',
    boxSizing: 'border-box',
    boxShadow: THEME.shadows.boxShadow,
    backgroundColor: THEME.background.colors.white
  }),
  listSection: css({
    padding: '8px 0',
    marginTop: '24px',
    boxSizing: 'border-box'
  })
};
