import { z } from 'zod';
import { UnionOfKeys } from '@/shared/types';
import { getFirstDay } from '@/shared/utils';
import { SearchSchema } from '@/shared/validations';

export const InvoicesSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({ month: SearchSchema.types.string(), keyword: SearchSchema.types.string() }),
    defaultValues: { month: getFirstDay() }
  }
});

export type InvoicesSearchSchemaType = z.infer<typeof InvoicesSearchSchema>;
export type InvoicesFilterFormValues = InvoicesSearchSchemaType['filter'];
export type InvoicesFilterFormKeys = UnionOfKeys<InvoicesFilterFormValues>;
