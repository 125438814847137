import { SocialAccountType, DouyinInfluencer } from '@/graphql';
import { DouyinInfluencerProfile } from './schemaTypes';

export const formatInfluencerProfile = (infl: DouyinInfluencer): DouyinInfluencerProfile => ({
  ...infl,
  socialAccountType: SocialAccountType.DOUYIN,
  posts:
    infl.popularPosts?.map(({ postId, thumbNail }) => ({
      thumbNail,
      id: postId,
      influencerId: Number(infl.id)
    })) || []
});
