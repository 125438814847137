import { z } from 'zod';
import { getToday } from '@/shared/utils';
import { UnionOfKeys } from '@/shared/types';
import { getThirtyDaysAgo } from '@/shared/utils';
import { SearchSchema } from '@/shared/validations';
import { YoutubeCompareOverviewHistory } from '@/graphql';

export const InfluencerProfileCompareSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      id: SearchSchema.types.string(),
      name: SearchSchema.types.string(),
      endDate: SearchSchema.types.string(),
      startDate: SearchSchema.types.string()
    }),
    defaultValues: { endDate: getToday(), startDate: getThirtyDaysAgo() }
  }
});

export type InfluencerProfileCompareSearchSchemaType = z.infer<typeof InfluencerProfileCompareSearchSchema>;
export type InfluencerYoutubeCompareGraphKeys = UnionOfKeys<YoutubeCompareOverviewHistory>;
