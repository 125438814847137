import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { TwitterAnalyticsPostsSortOrder } from '@/graphql';
import { defaultProfilePostsFilterSchema } from '@/shared/organisms';

export const AnalyticsTwitterPostsSearchSchema = SearchSchema.create({
  filter: defaultProfilePostsFilterSchema,
  sort: { field: TwitterAnalyticsPostsSortOrder }
});

export type AnalyticsTwitterPostsSearchSchemaType = z.infer<typeof AnalyticsTwitterPostsSearchSchema>;
