import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { PostListSortField, SocialPostType } from '@/graphql';

export const InfluencerScrapingPostsListSearchSchema = SearchSchema.create({
  sort: { field: PostListSortField },
  filter: {
    schema: z.object({
      name: SearchSchema.types.string(),
      keyword: SearchSchema.types.string(),
      type: SearchSchema.types.enum(SocialPostType)
    })
  }
});

export type InfluencerScrapingPostsListSearchSchemaType = z.infer<typeof InfluencerScrapingPostsListSearchSchema>;
