import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { InfluencersTabType, UnionOfKeys } from '@/shared/types';
import { Genders, SocialAccountInfluencerListSortFieldV2, SocialAccountStatus, SocialAccountType } from '@/graphql';
import {
  PostType,
  DefaultInfluencersProps,
  InfluencersFilterBaseSchema,
  InfluencersBaseSearchStateSchema
} from '../schemaTypes';

export const FacebookInfluencersFilterSchema = InfluencersFilterBaseSchema.pick({
  tab: true,
  minAge: true,
  maxAge: true,
  status: true,
  keyword: true,
  genders: true,
  regionIds: true,
  includeIds: true,
  countryIds: true,
  categoryIds: true,
  keywordField: true,
  minFollowers: true,
  maxFollowers: true,
  minPostPrice: true,
  maxPostPrice: true,
  minEngagementRate: true,
  maxEngagementRate: true,
  talentAgencyOrPartnerIds: true
});

// Don't put this in utils, it can be loaded after search schema created so the initial data might be wrong
export const defaultFacebookInfluencersFormValues = FacebookInfluencersFilterSchema.parse({
  tab: InfluencersTabType.FACEBOOK
});

export const FacebookInfluencersSearchSchema = SearchSchema.create({
  state: InfluencersBaseSearchStateSchema,
  sort: { field: SocialAccountInfluencerListSortFieldV2 },
  filter: {
    schema: FacebookInfluencersFilterSchema,
    defaultValues: defaultFacebookInfluencersFormValues
  }
});

export type FacebookInfluencersSearchSchemaType = z.infer<typeof FacebookInfluencersSearchSchema>;
export type FacebookInfluencersFilterFormValues = FacebookInfluencersSearchSchemaType['filter'];
export type FacebookInfluencersFilterFormKeys = UnionOfKeys<FacebookInfluencersFilterFormValues>;
export type FacebookInfluencersProps = DefaultInfluencersProps<FacebookInfluencerProfile>;
export interface FacebookInfluencerProfile {
  id: number;
  name: string;
  avatar?: string;
  posts: PostType[];
  age?: number | null;
  gender: Genders | '';
  likes?: number | null;
  isBrandAccount?: boolean;
  followers?: number | null;
  analyticsWarning?: boolean;
  engagementRate?: number | null;
  socialAccountId?: string;
  status: SocialAccountStatus | null;
  socialAccountType: SocialAccountType;
  country: { id?: string; name?: string };
}
