import { FilterProvider, FilterWrapper } from '@/shared/molecules';
import { Template, TemplateProps } from './Template';
import { useAssociatedAccountsFilter } from './hooks';

export type AssociatedAccountsFilterProps = Omit<TemplateProps, 'onSubmit'>;

export const AssociatedAccountsFilter = ({ keywordPlaceholder }: AssociatedAccountsFilterProps) => {
  const { filter, setFilterForm } = useAssociatedAccountsFilter();

  return (
    <FilterWrapper>
      <FilterProvider
        initialValues={filter}
        onSubmit={setFilterForm}
        basicFilter={<Template keywordPlaceholder={keywordPlaceholder} />}
      />
    </FilterWrapper>
  );
};

export { useAssociatedAccountsFilter };
export * from './schemaTypes';
