import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@/shared/utils';
import { RangeSliderInputField } from '@/shared/molecules';
import { MIN_MAX_VIEW_OPTIONS, MIN_VIEWS, MAX_VIEWS } from '@/shared/constants';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const ViewField = () => {
  const { t } = useTranslation();

  return (
    <RangeSliderInputField<InfluencersFilterFormKeys>
      step={10000}
      title={t('Avg View')}
      formatLabel={formatIntNumber}
      options={MIN_MAX_VIEW_OPTIONS}
      range={{ min: MIN_VIEWS, max: MAX_VIEWS }}
      minField={{ placeholder: formatIntNumber(MIN_VIEWS), name: 'minViews' }}
      maxField={{ placeholder: `${formatIntNumber(MAX_VIEWS)}+`, name: 'maxViews' }}
    />
  );
};
