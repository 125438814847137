import { useTranslation } from 'react-i18next';
import { QuerySocialAccountType } from '@/graphql';
import { SelectorFieldV2Props, SelectorFieldV2 } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

export const SocialMediaField = ({ multiple, ...props }: Omit<SelectorFieldV2Props, 'name'>) => {
  const { t } = useTranslation();
  const socialMediaOptions = [
    { label: t('Facebook'), value: QuerySocialAccountType.FACEBOOK },
    { label: t('Instagram'), value: QuerySocialAccountType.INSTAGRAM },
    { label: t('Instagram Story'), value: QuerySocialAccountType.INSTAGRAM_BUSINESS },
    { label: t('X(Twitter)'), value: QuerySocialAccountType.TWITTER },
    { label: t('YouTube'), value: QuerySocialAccountType.YOUTUBE },
    { label: t('TikTok'), value: QuerySocialAccountType.TIKTOK },
    { label: t('Douyin'), value: QuerySocialAccountType.DOUYIN },
    { label: t('Threads'), value: QuerySocialAccountType.THREADS },
    { label: t('Xiaohongshu'), value: QuerySocialAccountType.XHS }
  ];

  return (
    <SelectorFieldV2<InfluencersFilterFormKeys>
      multiple={multiple}
      title={t('Selector.Social Media')}
      options={socialMediaOptions}
      clearable={false}
      menuWidth="auto"
      name={multiple ? 'socialMedias' : 'socialMedia'}
      {...props}
    />
  );
};
