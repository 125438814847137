import { useTranslation } from 'react-i18next';
import { BasicFilterWrapper } from '@/shared/molecules';
import { InfluencerTabField, KeywordField, CountryField, CampaignCategoryField } from '../../shared';
import { BasicFilterDefaultProps } from '../../schemaTypes';

export const BasicFilter = ({ onSubmit, isEngagementPage }: BasicFilterDefaultProps) => {
  const { t } = useTranslation();

  return (
    <BasicFilterWrapper>
      <InfluencerTabField isEngagementPage={isEngagementPage} onSubmit={onSubmit} />
      <KeywordField onSubmit={onSubmit} placeholder={t<string>('Search.EngagementFilter')} />
      <CountryField onSubmit={onSubmit} css={{ maxWidth: '138px' }} multiple />
      <CampaignCategoryField
        title=""
        onSubmit={onSubmit}
        css={{ maxWidth: '203px' }}
        placeholder={t<string>('Selector.Campaign Category')}
        multiple
      />
    </BasicFilterWrapper>
  );
};
