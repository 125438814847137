import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import { FilterAction, UnionOfKeys } from '@/shared/types';
import { getToday, getThirtyDaysAgo } from '@/shared/utils';
import { CampaignType, CampaignSocialMediaType, CampaignReportOrderField } from '@/graphql';

export const ReportSearchSchema = SearchSchema.create({
  sort: { field: CampaignReportOrderField },
  filter: {
    schema: z.object({
      endDate: SearchSchema.types.string(),
      countryId: SearchSchema.types.string(),
      startDate: SearchSchema.types.string(),
      campaigns: SearchSchema.types.arrayString(),
      campaignCategoryIds: SearchSchema.types.arrayString(),
      campaignTypes: SearchSchema.types.arrayEnum(CampaignType),
      socialMedias: SearchSchema.types.arrayEnum(CampaignSocialMediaType)
    }),
    defaultValues: {
      endDate: getToday(),
      startDate: getThirtyDaysAgo(),
      campaignTypes: [CampaignType.ENGAGEMENT, CampaignType.MARKETPLACE]
    }
  }
});

export type ReportSearchSchemaType = z.infer<typeof ReportSearchSchema>;
export type ReportFilterFormValues = ReportSearchSchemaType['filter'];
export type ReportFilterFormKeys = UnionOfKeys<ReportFilterFormValues>;
export type ReportFilterProps = FilterAction<ReportFilterFormValues>;
