import { useTranslation } from 'react-i18next';
import { useCampaignTypesOptionsWithPermissions, useTranslateOptions } from '@/shared/hooks';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { SupportedSocialMediaType } from '@/shared/types';
import { getSocialMediaText } from '@/shared/utils';
import { InfluencerProfileCampaignsFilterFormKeys } from './schemaTypes';

interface TemplateProps {
  onSubmit?: () => void;
}

export const Template = ({ onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const allCampaignTypes = useCampaignTypesOptionsWithPermissions();
  const allCampaignTypeOptions = useTranslateOptions(allCampaignTypes);
  const socialMediaOptions = [
    SupportedSocialMediaType.FACEBOOK,
    SupportedSocialMediaType.INSTAGRAM,
    SupportedSocialMediaType.INSTAGRAM_STORY,
    SupportedSocialMediaType.TWITTER,
    SupportedSocialMediaType.YOUTUBE,
    SupportedSocialMediaType.TIKTOK,
    SupportedSocialMediaType.DOUYIN,
    SupportedSocialMediaType.THREADS
  ].map((campaignType) => ({ label: getSocialMediaText(campaignType), value: campaignType }));

  return (
    <BasicFilterWrapper css={{ padding: '16px', maxWidth: '80%' }}>
      <TextSearchField<InfluencerProfileCampaignsFilterFormKeys>
        name="keyword"
        title="Keyword"
        onSubmit={onSubmit}
        placeholder={t<string>('Search.PostContent')}
      />
      <SelectorField<InfluencerProfileCampaignsFilterFormKeys>
        name="campaignType"
        onSubmit={onSubmit}
        title={t('Selector.Campaign Type')}
        css={{ maxWidth: '240px' }}
        options={allCampaignTypeOptions}
        singleSelectorProps={{ hideDeselectOption: true }}
      />
      <SelectorField<InfluencerProfileCampaignsFilterFormKeys>
        name="socialMedias"
        title={t('Selector.Social Media')}
        onSubmit={onSubmit}
        css={{ maxWidth: '240px' }}
        options={socialMediaOptions}
        multiple
      />
    </BasicFilterWrapper>
  );
};
