import { useTranslation } from 'react-i18next';
import { useUserRoles } from '@/auth';
import { BasicFilterWrapper, SelectorField, TextSearchField } from '@/shared/molecules';
import { AccountListDomain } from '../types';
import { AccountFilterFormKeys } from './schemaTypes';
import { useAccountsFilterOptions } from './hooks';

export interface TemplateProps {
  onSubmit?: () => void;
  keywordPlaceholder?: string;
  domain: AccountListDomain;
}

export const Template = ({ onSubmit, keywordPlaceholder, domain }: TemplateProps) => {
  const { isAdminStaff } = useUserRoles();
  const { t } = useTranslation();
  const { allAvailableCountryOptions, analyticsPlanOptions, verifiedOptions, tikTokAdvertiserPlanOptions } =
    useAccountsFilterOptions();

  const isAdvertiserPage = domain === 'advertiser';
  const selectorWidth = isAdvertiserPage ? '160px' : '194px';

  return (
    <BasicFilterWrapper css={{ padding: '12px 16px 16px' }}>
      <TextSearchField<AccountFilterFormKeys> name="keyword" onSubmit={onSubmit} placeholder={keywordPlaceholder} />

      {isAdvertiserPage ? (
        <>
          <SelectorField<AccountFilterFormKeys>
            name="tiktokPlan"
            onSubmit={onSubmit}
            css={{ maxWidth: selectorWidth }}
            options={tikTokAdvertiserPlanOptions}
            placeholder={t<string>('Selector.TikTok Special')}
          />

          <SelectorField<AccountFilterFormKeys>
            name="analyticsPlan"
            onSubmit={onSubmit}
            options={analyticsPlanOptions}
            css={{ maxWidth: selectorWidth }}
            placeholder={t<string>('Selector.Analytics Plan')}
          />
        </>
      ) : null}

      <SelectorField<AccountFilterFormKeys>
        name="countryIds"
        onSubmit={onSubmit}
        css={{ maxWidth: selectorWidth }}
        options={allAvailableCountryOptions}
        placeholder={t<string>('Selector.Countries')}
        multiple
      />

      {isAdminStaff ? (
        <SelectorField<AccountFilterFormKeys>
          onSubmit={onSubmit}
          name="verifiedStatus"
          options={verifiedOptions}
          css={{ maxWidth: selectorWidth }}
          placeholder={t<string>('Selector.Verification Status')}
        />
      ) : null}
    </BasicFilterWrapper>
  );
};
