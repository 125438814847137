import { z } from 'zod';
import { SearchSchema } from '@/shared/validations';
import {
  PackageSummary,
  SocialAccountType,
  PackageInfluencer,
  SocialAccountStatus,
  PackageSocialAccount,
  ProposalJobDescriptionBreakDownMode
} from '@/graphql';
import { ProposeInfluencersTableMode } from '../../../Influencers';

export interface PackageProposedInfluencer extends PackageSummary, Omit<PackageInfluencer, 'accountList' | 'summary'> {
  accountList: readonly PackageSocialAccount[];
}
export interface RowPackageInfluencerData {
  id: number;
  name: string;
  avatar?: string | null;
  status: SocialAccountStatus;
}

export interface RowPackageSocialAccountData {
  id: number;
  name?: string | null;
  lastIndex?: boolean;
  socialMedia: SocialAccountType;
}
export interface RowPackageStatsData {
  budget: number;
  profit: number;
  influencerCost: number;
  addedDate?: string | null;
  followersCount?: number | null;
  engagementRateByViews?: number | null;
  engagementRateByFollowers?: number | null;
  engagementPosted?: number | null;
  engagementProposed?: number | null;
}

export interface RowPackageData {
  index: number;
  stats: RowPackageStatsData;
  influencer: RowPackageInfluencerData;
  socialAccount?: RowPackageSocialAccountData;
}

export enum EditablePackageInfluencersFieldKeys {
  budget = 'budget',
  profit = 'profit',
  influencerCost = 'influencerCost'
}
export enum PackageContentTabType {
  INFLUENCERS_LIST = 'INFLUENCERS_LIST',
  ADD_INFLUENCER = 'ADD_INFLUENCER'
}

export const PackageProposalSearchSchema = SearchSchema.create({
  filter: {
    schema: z.object({
      keyword: SearchSchema.types.string(),
      tableViewMode: SearchSchema.types.enum(ProposeInfluencersTableMode),
      jdMode: SearchSchema.types.enum(ProposalJobDescriptionBreakDownMode)
    }),
    defaultValues: {
      tableViewMode: ProposeInfluencersTableMode.VIEW,
      jdMode: ProposalJobDescriptionBreakDownMode.SOCIAL_BREAKDOWN
    }
  }
});

export type PackageProposalSearchSchemaType = z.infer<typeof PackageProposalSearchSchema>;
export type PackageProposalFilterFormValues = PackageProposalSearchSchemaType['filter'];
