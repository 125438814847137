import { useTranslation } from 'react-i18next';
import { FormStyle, Label } from '@/shared/atoms';
import { AUDIENCE_RATIO_OPTIONS, AUDIENCE_GENDER_OPTIONS, AUDIENCE_AGE_GROUP_OPTIONS } from '@/shared/constants';
import { useTranslateOptions } from '@/shared/hooks';
import { StyledAdvancedFilter, SelectorField } from '@/shared/molecules';
import { InfluencersFilterFormKeys } from '../schemaTypes';

interface DemographicFieldProps {
  title?: string;
  className?: string;
}

export const DemographicField = ({ title, className }: DemographicFieldProps) => {
  const { t } = useTranslation();
  const genderOptions = useTranslateOptions(AUDIENCE_GENDER_OPTIONS);
  const ageOptions = [{ label: t('All'), value: '' }, ...AUDIENCE_AGE_GROUP_OPTIONS];

  return (
    <StyledAdvancedFilter.FieldWrapper className={className}>
      <Label title={title ?? t('Demographics')} />

      <FormStyle.FieldsGroup>
        <StyledAdvancedFilter.FieldWrapper css={{ display: 'flex', alignItems: 'center' }}>
          <Label title={t('Selector.Gender')} css={{ flex: 0 }} />
          <SelectorField<InfluencersFilterFormKeys> name="audienceGender" options={genderOptions} />
        </StyledAdvancedFilter.FieldWrapper>

        <StyledAdvancedFilter.FieldWrapper css={{ display: 'flex', alignItems: 'center' }}>
          <Label title={t('Selector.Age')} css={{ flex: 0 }} />
          <SelectorField<InfluencersFilterFormKeys>
            name="audienceAge"
            options={ageOptions}
            singleSelectorProps={{ hideDeselectOption: true }}
          />
        </StyledAdvancedFilter.FieldWrapper>

        <StyledAdvancedFilter.FieldWrapper css={{ display: 'flex', alignItems: 'center' }}>
          <Label title={t('Selector.Ratio')} css={{ flex: 0 }} />
          <SelectorField<InfluencersFilterFormKeys> name="audienceRatio" options={AUDIENCE_RATIO_OPTIONS} />
        </StyledAdvancedFilter.FieldWrapper>
      </FormStyle.FieldsGroup>
    </StyledAdvancedFilter.FieldWrapper>
  );
};
